import React from 'react'

import AuditLogConfig from '@app/src/pages/Configurations/ConfigurationsPages/AuditLog/AuditLogsConfig'
import AutomationsConfig from '@app/src/pages/Configurations/ConfigurationsPages/Automation/AutomationsConfig'
import FileRepository from '@app/src/pages/Configurations/ConfigurationsPages/FileRepository/FileRepository'
import UsersConfig from '@app/src/pages/Configurations/ConfigurationsPages/Users/UsersConfig'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import AssessmentConfig from './ConfigurationsPages/Assessment/AssessmentConfig'
import AssessmentAdminConfig from './ConfigurationsPages/AssessmentAdmin/AssessmentAdminConfig'
import CategoriesConfig from './ConfigurationsPages/Category/CategoriesConfig'
import ClaimsReviewConfig from './ConfigurationsPages/ClaimsReview/ClaimsReviewCollection'
import ColleaguesConfig from './ConfigurationsPages/Colleague/ColleaguesConfig'
import EdciConfig from './ConfigurationsPages/Edci/EdciConfig'
import OrganizationCollection from './ConfigurationsPages/Organization/OrganizationCollection'
import OrganizationDetails from './ConfigurationsPages/Organization/OrganizationDetails'
import OrganizationInfoConfig from './ConfigurationsPages/OrganizationInfo/OrganizationInfoConfig'
import RiskScreeningConfig from './ConfigurationsPages/RiskScreening/RiskScreeningConfig'
import SuggestedContactsConfig from './ConfigurationsPages/SuggestedContacts/SuggestedContactsConfig'
import UserDetails from './ConfigurationsPages/Users/UserDetails'
import OrganizationPreferencesConfig from './ConfigurationsPages/OrganizationSettings/OrganizationPreferencesConfig'

interface Props {
  content: CONFIGURATION_PAGE_IDS
  configurationsSubPage?: string
}

const ConfigurationsContent: React.FC<Props> = function ({ content, configurationsSubPage }) {
  switch (content) {
    case CONFIGURATION_PAGE_IDS.Automations:
      return <AutomationsConfig />

    case CONFIGURATION_PAGE_IDS.AuditLogs:
      return <AuditLogConfig />

    case CONFIGURATION_PAGE_IDS.FileRepository:
      return <FileRepository />

    case CONFIGURATION_PAGE_IDS.Colleagues:
      return <ColleaguesConfig />

    case CONFIGURATION_PAGE_IDS.Categories:
      return <CategoriesConfig />

    case CONFIGURATION_PAGE_IDS.RiskScreening:
      return <RiskScreeningConfig />

    case CONFIGURATION_PAGE_IDS.Assessments:
      return <AssessmentConfig />

    case CONFIGURATION_PAGE_IDS.EDCI:
      return <EdciConfig />

    case CONFIGURATION_PAGE_IDS.Users:
      return configurationsSubPage ? <UserDetails id={configurationsSubPage} /> : <UsersConfig />

    case CONFIGURATION_PAGE_IDS.Organizations:
      return configurationsSubPage ? <OrganizationDetails id={configurationsSubPage} /> : <OrganizationCollection />

    case CONFIGURATION_PAGE_IDS.OrganizationInfo:
      return <OrganizationInfoConfig />

    case CONFIGURATION_PAGE_IDS.OrganizationPreferences:
      return <OrganizationPreferencesConfig />

    case CONFIGURATION_PAGE_IDS.ClaimsReview:
      return <ClaimsReviewConfig />

    case CONFIGURATION_PAGE_IDS.SuggestedContacts:
      return <SuggestedContactsConfig />

    case CONFIGURATION_PAGE_IDS.AssessmentsAdminConfiguration:
      return <AssessmentAdminConfig />
  }
}

export default ConfigurationsContent
