import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import LoadingButton from '@app/src/components/LoadingButton'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import useFetchAiSuggestions, { AiSuggestion } from '@app/src/pages/Questionnaire/hooks/useFetchAiSuggestions'
import AiSuggestionDrawer from '@app/src/pages/Questionnaire/Provider/Report/AiSuggestionDrawer'
import PreviousAnswerDrawer from '@app/src/pages/Questionnaire/Provider/Report/PreviousAnswerDrawer'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import { AutoAwesomeOutlined, CheckCircleOutline, HistoryOutlined } from '@mui/icons-material'
import React from 'react'
import { useIntl } from 'react-intl'

interface SuggestAnswerButtonProps {
  requestItem: RequestItem
  questionType: string
  onApplyPreviousAnswer: () => Promise<void>
  onApplyAiSuggestion: (value: string) => void
  isPreview: boolean
  isLoadingCloneFile?: boolean
}

const suggestionIsSet = (suggestion: AiSuggestion | undefined, questionType: string): suggestion is AiSuggestion => {
  if (!suggestion) return false
  if (questionType?.toLowerCase() === QuestionTypes.Options.toLowerCase()) {
    return Boolean(suggestion.selectedOptions?.length)
  }

  return Boolean(suggestion?.response?.trim().length)
}

const SuggestAnswerButton: React.FC<SuggestAnswerButtonProps> = ({
  requestItem,
  questionType,
  onApplyPreviousAnswer,
  onApplyAiSuggestion,
  isPreview,
  isLoadingCloneFile,
}) => {
  const { request, showPreviousAnswerSuggestions } = useReport()
  const { formatMessage } = useIntl()
  const { hideInputField, sectionNumber, itemNumber, previousAnswer, responseItemView } = useResponseItemContext()
  const { openDrawer, closeDrawer } = useDrawer()

  const { fetchedData: aiSuggestion, isLoadingAiSuggestions } = useFetchAiSuggestions(
    requestItem,
    questionType,
    isPreview,
    request?.periodName,
  )

  if (hideInputField) return null

  const aiSuggestionsExist = suggestionIsSet(aiSuggestion, questionType) && !isLoadingAiSuggestions

  if (previousAnswer && showPreviousAnswerSuggestions) {
    if (responseItemView === ResponseItemView.SuggestedAnswerAccepted)
      return (
        <LoadingButton size="small" variant="text" startIcon={<CheckCircleOutline />} loading={isLoadingCloneFile}>
          {formatMessage({ id: 'suggestedAnswers.previousAnswerApplied' })}
        </LoadingButton>
      )

    return (
      <LoadingButton
        size="small"
        variant="contained"
        startIcon={<HistoryOutlined />}
        loading={isLoadingCloneFile}
        onClick={() =>
          openDrawer(
            <PreviousAnswerDrawer
              previousAnswer={previousAnswer}
              requestItem={requestItem}
              questionType={questionType}
              onApply={onApplyPreviousAnswer}
              closeDrawer={closeDrawer}
              sectionNumber={sectionNumber}
              itemNumber={itemNumber}
            />,
          )
        }
      >
        {formatMessage({ id: 'suggestedAnswers.viewPreviousAnswer' })}
      </LoadingButton>
    )
  }

  if (aiSuggestionsExist) {
    if (responseItemView === ResponseItemView.SuggestedAnswerAccepted)
      return (
        <LoadingButton
          size="small"
          loading={isLoadingCloneFile}
          variant="text"
          startIcon={<CheckCircleOutline />}
          sx={({ palette }) => ({
            color: palette.communication.dark,
          })}
        >
          {formatMessage({ id: 'suggestedAnswers.aiAnswerApplied' })}
        </LoadingButton>
      )

    return (
      <LoadingButton
        size="small"
        loading={isLoadingCloneFile}
        variant="contained"
        startIcon={<AutoAwesomeOutlined />}
        onClick={() =>
          openDrawer(
            <AiSuggestionDrawer
              suggestion={aiSuggestion}
              requestItem={requestItem}
              questionType={questionType}
              onApply={onApplyAiSuggestion}
              closeDrawer={closeDrawer}
              sectionNumber={sectionNumber}
              itemNumber={itemNumber}
            />,
          )
        }
        sx={({ palette }) => ({
          backgroundColor: palette.communication.light,
          color: palette.communication.dark,
          ':hover': {
            color: 'white',
          },
        })}
      >
        {formatMessage({ id: 'suggestedAnswers.viewAiSuggestion' })}
      </LoadingButton>
    )
  }

  return null
}
export default SuggestAnswerButton
