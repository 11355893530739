import AssessmentCell from '@app/src/components/Table/Cells/AssessmentCell'
import ChipCell from '@app/src/components/Table/Cells/ChipCell'
import CompanyCell from '@app/src/components/Table/Cells/CompanyCell'
import DateCell from '@app/src/components/Table/Cells/DateCell'
import Assessment from '@app/src/types/assessment'
import { TableRow } from '@mui/material'
import React from 'react'

type AssessmentDialogRowProps = {
  row: Assessment
}

export const AssessmentDialogRow = ({ row: assessment }: AssessmentDialogRowProps): JSX.Element => {
  return (
    <TableRow>
      <CompanyCell company={assessment.provider} />
      <ChipCell label={assessment.periodName} />
      <AssessmentCell assessment={assessment} />
      <DateCell value={assessment.updatedAt} />
    </TableRow>
  )
}

export default AssessmentDialogRow
