import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResource } from '@app/src/api/fetchHooks'
import { Permissions, usePermissions } from '@app/src/auth/permissions'
import { useQueryState } from '@app/src/hooks/queryState'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import {
  ReportAccessModel,
  Request,
  RequestHistory,
  ResponseItem,
  ResponseWithPreviousCorrectionNeededItem,
} from '@app/src/types/resourceExplorer'
import { dashboard } from '@app/src/wf-constants/paths'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import ReportContext, { LanguageInfo, ReportContextProps } from './ReportContext'

const getMostRecentResponseItems = (data?: ResponseItem[], request?: Request): ResponseItem[] => {
  const responseItemsMap: { [key: number]: ResponseItem } = {}

  data?.forEach(item => {
    const questionId = item.requestItem.questionId
    const currentItem = responseItemsMap[questionId]
    const itemOnCurrentRequest = request?.sections
      ?.flatMap(section => section?.items)
      .find(reqItem => reqItem?.questionId === questionId)
    const isNotRequiredOrHasAnswer = !itemOnCurrentRequest?.isRequired || !item.cannotAnswer

    if (
      (!currentItem || new Date(item.response.submittedAt) > new Date(currentItem.response.submittedAt)) &&
      isNotRequiredOrHasAnswer
    ) {
      responseItemsMap[questionId] = item
    }
  })

  return Object.values(responseItemsMap)
}

type ReportContextProviderProps = {
  children: ReactNode
}

export enum ReuseAllQuestionsStates {
  ApplyAll = 'ApplyAll',
  Review = 'Review',
}

const ReportContextProvider: FC<ReportContextProviderProps> = ({ children }: ReportContextProviderProps) => {
  const history = useHistory()

  const [requestId, setRequestId] = useQueryState('requestId')
  const [responseId, setResponseId] = useQueryState('responseId')
  const [showAccessNudge, setShowAccessNudge] = useState(false)
  const [isLoadingAccessNudge, setLoadingAccessNudge] = useState(true)
  const [reuseAllQuestionsState, setReuseAllQuestionsState] = useState<ReuseAllQuestionsStates>(
    ReuseAllQuestionsStates.Review,
  )
  const [showPreviousAnswerSuggestions, setShowPreviousAnswerSuggestions] = useState(true)

  const [locale, setLocale] = useState<LanguageInfo | null>(null)
  const { hasPermission } = usePermissions()

  const isTransparency = hasPermission(Permissions.TRANSPARENCY_USER)

  const { data: request, isLoading: isRequestLoading } = useFetchResource<Request>({
    endpoint: endpoints.request(requestId, locale?.code),
    key: [FetchKey.Request, Number(requestId), locale?.code],
    options: {
      enabled: Boolean(requestId),
      onError: () => {
        history.replace(dashboard)
      },
    },
  })

  const questionIds = request?.sections?.flatMap(section => section?.items.map(question => question?.questionId))

  const { items: previouslyRespondedQuestions, isLoading: isPreviouslyRespondedQuestionsLoading } =
    useFetchCollectionWithPost<ResponseItem>({
      key: FetchKey.ResponseItemsCollection,
      endpoint: endpoints.responseItemsCollection,
      payload: {
        filter: [
          {
            name: 'requestItem.questionId',
            filters: [{ operator: Operators.In, value: questionIds }],
          },
          {
            name: 'response.targetRequestId',
            filters: [{ operator: Operators.NotEqualTo, value: request?.id }],
          },
          {
            name: 'response.isLatestSubmitted',
            filters: [{ operator: Operators.EqualTo, value: true }],
          },
        ],
        include: ['response.request.subscriptions.creatorOrganization'],
      },
      options: {
        enabled: Boolean(request) && isTransparency,
      },
    })

  const uniquePreviouslyRespondedQuestions = useMemo(
    () => getMostRecentResponseItems(previouslyRespondedQuestions, request),
    [previouslyRespondedQuestions, request],
  )

  const {
    data: response,
    isLoading: isResponseLoading,
    isFetching: isResponseFetching,
  } = useFetchResource<ResponseWithPreviousCorrectionNeededItem>({
    endpoint: endpoints.response(responseId, locale?.code, isTransparency),
    key: [FetchKey.Response, Number(responseId), locale?.code],
    options: {
      enabled: Boolean(responseId),
      onError: () => {
        setResponseId(undefined, true)
      },
    },
  })

  const { data: currentAccess, isLoading: isCurrentAccessLoading } = useFetchResource<ReportAccessModel>({
    key: [FetchKey.ResponseSharing, Number(requestId)],
    endpoint: endpoints.getAccessForReport(requestId),
    options: { enabled: Boolean(requestId) },
  })

  const { data: requestHistory, isLoading: isRequestHistoryLoading } = useFetchResource<RequestHistory[]>({
    key: [FetchKey.RequestHistory, Number(requestId)],
    endpoint: endpoints.requestHistory(requestId),
    options: {
      enabled: Boolean(requestId),
    },
  })

  const { items: requestsPerPeriod, isLoading: isRequestsPerPeriodLoading } = useFetchCollectionWithPost<Request>({
    key: FetchKey.RequestPerPeriod,
    endpoint: endpoints.requests,
    payload: {
      include: ['responses'],
      filter: [
        {
          name: 'questionnaireTemplateId',
          filters: [
            {
              value: request?.questionnaireTemplateId,
              operator: Operators.EqualTo,
            },
          ],
        },

        {
          name: 'responderOrganizationId',
          filters: [
            {
              value: request?.responderOrganizationId,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      sort: {
        target: 'periodName',
        order: SortOrder.ASCENDING,
      },
    },
    options: {
      enabled: Boolean(request),
    },
  })

  const latestHistory = useMemo(() => requestHistory?.[0], [requestHistory])
  const reportStatus = useMemo(
    () => (responseId !== latestHistory?.responseId ? response?.draftStatus : latestHistory?.status),
    [responseId, latestHistory, response],
  )

  const updateRequestIdParam: ReportContextProps['updateRequestIdParam'] = (requestId?) => {
    setRequestId(requestId, true)
  }

  const updateResponseIdParam: ReportContextProps['updateResponseIdParam'] = (responseId?) => {
    setResponseId(responseId, true)
  }

  useEffect(() => {
    if (!responseId && latestHistory?.responseId) {
      setResponseId(latestHistory.responseId, true)
    }
  }, [responseId, latestHistory])

  return (
    <ReportContext.Provider
      value={{
        responseId: responseId ? Number(responseId) : undefined,
        requestId: requestId ? Number(requestId) : undefined,
        request,
        response,
        previouslyRespondedQuestions: uniquePreviouslyRespondedQuestions,
        requestsPerPeriod,
        currentAccess,
        requestHistory,
        latestHistory,
        reportStatus,
        locale,
        showAccessNudge,
        isLoadingAccessNudge,
        isRequestLoading,
        isResponseLoading,
        isResponseFetching,
        isCurrentAccessLoading,
        isRequestHistoryLoading,
        isRequestsPerPeriodLoading,
        isPreviouslyRespondedQuestionsLoading,
        reuseAllQuestionsState,
        updateRequestIdParam,
        updateResponseIdParam,
        setLocale,
        setShowAccessNudge,
        setLoadingAccessNudge,
        setReuseAllQuestionsState,
        showPreviousAnswerSuggestions,
        setShowPreviousAnswerSuggestions,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

export default ReportContextProvider
