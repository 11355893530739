export const providers = '/providers/:view?'
export const resourceExplorer = '/resource/:resourceType/:resourceId/:activeTabParam?/:view?/:requestTemplateId?'
export const contacts = '/resources/contacts'
export const subscriptions = '/resources/subscriptions'
export const dataHubCollection = '/datahub/:view/:viewType?'
export const transparency = '/transparency'
export const dashboard = '/dashboard'
export const sourcingDashboard = '/sourcing/dashboard'
export const sourcingStartDashboard = '/sourcing/dashboard/start'
export const investmentDashboard = '/investment/dashboard'
export const financeDashboard = '/esgreport/dashboard'
export const transparencyDashboard = '/transparency/dashboard'
export const transparencyProviderProgress = '/transparency/dashboard/progress'
export const sourcingProviderProgress = '/providerProgress'
export const sourcingProviderProgressWithId = '/resource/supplier/:supplierId/providerProgress'
export const configurations = '/configurations/:configurationsPage?/:configurationsSubPage?'
export const sustainabilityLibrary =
  '/sustainability-library/:configurationsPage?/:configurationsSubPage?/:activeTabParam?/:activeTabSubId?'
export const evaluations = '/evaluations'
export const providerRisk = '/provider-risk'
export const notifications = '/notifications'
export const request = '/request/:flow/:requestId'
export const response = '/response/:flow/:responseId'

export const productsCollection = '/product-mapping/products'
export const createProduct = '/product-mapping/products/create'
export const product = '/product-mapping/products/:id?'
export const mappingNodeCollection = '/product-mapping/value-chains'
export const receivedMappingRequestCollection = '/product-mapping/received-requests'
export const mappingRequest = '/product-mapping/received-requests/:id'
export const sentMappingRequestCollection = '/product-mapping/sent-requests'
export const receivedMappingResponseCollection = '/product-mapping/responses'
export const productMappingCompanies = '/product-mapping/companies'
export const productMappingContacts = '/product-mapping/contacts'

export const logout = '/logout/:sso?'
export const userSettings = '/user/:page?'
export const co2eCalculator = '/co2e-calculator'
export const engagedProviders = '/engaged-providers'
export const editTemplate = '/template-builder/:templateId?'
export const questionnaireBuilderCustomQuestion = '/questionnaire-builder/question/:questionId?'
export const questionCollection = '/resources/questions'

export const reporting = '/reporting'

export const manageRequest = '/manage/:view?'
export const riskScreening = '/risk-screening/:view?'

export const baseAssessment = '/base-assessment/:view?'
export const providerBaseAssessmentWithId = '/resource/supplier/:supplierId/base-assessment'
export const assessments = '/assessments/:view?'
export const assessmentTemplateCollection = '/sustainability-library/assessments'

const paths = {
  resourceExplorer,
  providers,
  contacts,
  logout,
  request,
  response,
  dataHubCollection,
  configurations,
  sustainabilityLibrary,
  evaluations,
  dashboard,
  sourcingDashboard,
  sourcingStartDashboard,
  investmentDashboard,
  financeDashboard,
  notifications,
  userSettings,
  transparency,
  transparencyDashboard,
  subscriptions,
  co2eCalculator,
  engagedProviders,
  reporting,
  editTemplate,
  productsCollection,
  createProduct,
  product,
  valueChainCollection: mappingNodeCollection,
  mappingRequestCollection: receivedMappingRequestCollection,
  mappingRequest,
  mappingNodeCollection,
  sentMappingRequestCollection,
  receivedMappingResponseCollection,
  productMappingCompanies,
  productMappingContacts,
  manageRequest,
  transparencyProviderProgress,
  sourcingProviderProgress,
  sourcingProviderProgressWithId,
  providerRisk,
  questionCollection,
  riskScreening,
  questionnaireBuilderCustomQuestion,
  baseAssessment,
  providerBaseAssessmentWithId,
  assessments,
  assessmentTemplateCollection,
}

export default paths
