import DataHubInsightsScene from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataHubInsightsScene'
import DataInsightsModalContextProvider from '@app/src/pages/ResourceCollection/Collections/DataHub/DataInsights/DataInsightsModalContextProvider'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import React from 'react'

type StatisticsProps = {
  tabs: ResourceCollectionSceneProps['tabs']
}

export const dataHubAllowedFilters = [
  'requestItem.questionId',
  'requestItem.template.options.id',
  'response.request.questionnaireTemplateId',
  'response.request.periodName',
  'requestItem.template.id',
  'response.request.subscriptions.target.country.id',
  'response.request.subscriptions.target.organizationId',
  'response.request.template.id',
  'requestItem.section.template.id',
  'response.submittedAt',
  'cannotAnswer',
  'answer',
  'response.request.subscriptions.target.providerApprovalStatus',
  'response.request.subscriptions.target.priority',
  'response.request.subscriptions.target.finalRiskRating',
  'response.request.subscriptions.target.activityStatus',
  'response.request.subscriptions.target.supplierUsage',
  'response.request.subscriptions.target.tier',
  'response.request.subscriptions.target.ownerUserId',
  'response.request.subscriptions.target.categoryOptions.id',
  'response.request.template.tags.tag',
  'response.verifications.externalVerificationStatus',
  'response.verifications.internalVerificationStatus',
  'response.request.subscriptions.target.mappingNodes.actorTypeModel.id',
  'response.request.subscriptions.target.mappingNodes.tier',
  'response.request.subscriptions.target.spends.amount',
  'response.request.subscriptions.target.spends.periodName',
  'fileScreeningResults.result',
]

const DataInsights: React.FC<StatisticsProps> = ({ tabs }) => {
  return (
    <DataInsightsModalContextProvider>
      <DataHubInsightsScene tabs={tabs} />
    </DataInsightsModalContextProvider>
  )
}

export default DataInsights
