import { SortableHeaderProps } from '@app/src/components/Table'
import TableHeaderCell from '@app/src/components/Table/Cells/TableHeaderCell'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import { TableRow } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'

type AssessmentDialogHeaderProps = {
  assessmentTemplate: AssessmentTemplate
} & SortableHeaderProps

const AssessmentDialogHeader: React.FC<AssessmentDialogHeaderProps> = ({
  assessmentTemplate,
  toggleSorting,
  activeSorting,
}): JSX.Element => {
  const { formatMessage } = useIntl()

  return (
    <TableRow>
      <TableHeaderCell
        label={formatMessage({ id: 'resourceCollections.general.company' })}
        sorting={{ name: 'providerId', toggleSorting, activeSorting }}
      />
      <TableHeaderCell label={formatMessage({ id: 'schemas.request.period' })} />
      <TableHeaderCell label={assessmentTemplate.name} />
      <TableHeaderCell label={formatMessage({ id: 'assessments.evaluated' })} />
    </TableRow>
  )
}

export default AssessmentDialogHeader
