import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchFacets } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import DrawerViewRiskAssessment from '@app/src/components/Drawer/Views/DrawerViewRiskAssessment'
import { useDrawer_DEPRECATED } from '@app/src/components/Drawer_DEPRECATED'
import UpdateProvidersDrawer from '@app/src/components/ManageProviderDrawer/UpdateProvidersDrawer'
import Table from '@app/src/components/Table'
import { StandardAssessmentsExportColumnsAccessor } from '@app/src/export-columns/standardAssessments'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import { CONFIGURATION_PAGE_IDS } from '@app/src/pages/Configurations/ConfigurationsScene'
import { ResourceCollectionSceneProps } from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import ResourceCollectionScene from '@app/src/pages/ResourceCollection/index'
import { SortOrder } from '@app/src/types/filter'
import { Provider } from '@app/src/types/organizations'
import { ResourceTypes } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import AssessmentFilters from '../../Filters/AssessmentFilters'
import { transformProviderAssessmentFilterNames } from '../../Filters/StatisticsConstants'
import { Operators } from '../../Filters/useFilters'
import AssessmentHeader from './AssessmentHeader'
import AssessmentRow from './AssessmentRow'

type AssessmentCompareSceneProps = Pick<ResourceCollectionSceneProps, 'actionButtons' | 'tabs'>

const allowedFilters = [
  'assessments.id',
  'assessments.periodName',
  'assessments.totalLevel',
  'assessments.assessmentTemplate.id',
  'id',
  'name',
  'provider.country.id',
  'provider.createdAt',
  'provider.ownerUserId',
  'provider.websiteAddress',
  'provider.vatNumber',
  'provider.registrationNumber',
  'provider.linkStatus',
  'categoryOptions.id',
  'provider.activityStatus',
  'provider.finalRiskRating',
  'provider.priority',
  'provider.providerApprovalStatus',
  'provider.supplierUsage',
  'provider.tier',
  'organization.id',
  'provider.mappingNodes.actorTypeModel.id',
  'provider.mappingNodes.tier',
  'assessmentTemplate.id',
  'periodName',
  'provider.id',
  'provider.categoryOptions.id',
  'totalLevel',
]

const AssessmentCompareScene: React.FC<AssessmentCompareSceneProps> = ({ ...props }) => {
  const { formatMessage } = useIntl()
  const { hasPermission } = usePermissions()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()
  const [selectedProviderIds, setSelectedProviderIds] = useState<Array<number>>([])
  const userFilters = useGetApiQueryFilters(allowedFilters)
  const canAccessAssessmentTemplate = hasPermission(Permissions.ASSESSMENT_TEMPLATE_ACCESS)
  const [isUpdateDrawerOpen, openUpdateDrawer, closeUpdateDrawer] = useDrawer_DEPRECATED(false)
  const { openDrawer } = useDrawer()

  const cleanFilters = transformProviderAssessmentFilterNames(userFilters)

  const basePayload = {
    filter: [
      {
        name: 'assessmentTemplateId',
        filters: [
          {
            operator: Operators.NotEqualTo,
            value: 0,
          },
        ],
      },
    ],
    sort: sorting,
    include: ['assessmentTemplate', 'provider.organization.country.risks.riskType'],
  }

  const payload = {
    filter: [
      ...cleanFilters,
      {
        name: 'assessments.id',
        filters: [
          {
            operator: Operators.NotEqualTo,
            value: 0,
          },
        ],
      },
    ],
    sort: sorting,
    include: ['categoryOptions', 'organization', 'assessments', 'assessments.assessmentTemplate'],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const {
    items: providers,
    count,
    isLoading,
    isFetching,
    isError,
  } = useFetchCollectionWithPost<Provider>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.providersCollection,
    payload,
    options: { enabled: canAccessAssessmentTemplate },
  })

  const {
    facets: [assessmentTemplates = []],
  } = useFetchFacets({
    key: [FetchKey.FilterFacet, 'assessmentTemplate.name'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'assessmentTemplate.name' }],
    filter: userFilters,
    sort: {
      order: SortOrder.ASCENDING,
      target: 'value',
    },
  })

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, provider: Provider) => {
    if (e.target.checked) {
      setSelectedProviderIds(prev => [...prev, provider.id])
    } else {
      setSelectedProviderIds(prev => prev.filter(providerId => providerId !== provider.id))
    }
  }

  return (
    <>
      <ResourceCollectionScene
        enableScroll
        title={formatMessage({ id: 'navbar.assessments' })}
        filter={
          <AssessmentFilters
            allowedFilters={allowedFilters}
            preSelectedTemplates={assessmentTemplates.map(template => Number(template.value))}
          />
        }
        actionButtons={[
          {
            label: formatMessage({ id: 'assessments.setAssessments' }),
            variant: 'outlined',
            to: generatePath(paths.configurations, {
              configurationsPage: CONFIGURATION_PAGE_IDS.Assessments,
            }),
          },
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Assessment}
                  count={count}
                  userFilter={userFilters}
                  exportColumns={StandardAssessmentsExportColumnsAccessor}
                  rawExportPayload={basePayload}
                />,
              ),
            disabled: isLoading || count === 0,
          },
        ]}
        buttonRow={
          selectedProviderIds.length
            ? [
                {
                  label: formatMessage(
                    { id: 'resourceCollections.general.categorize' },
                    { count: selectedProviderIds.length },
                  ),
                  onClick: openUpdateDrawer,
                  disabled: isLoading || isFetching,
                },
                {
                  label: formatMessage({ id: 'assessments.yourAssessment' }, { count: selectedProviderIds.length }),
                  onClick: () => openDrawer(<DrawerViewRiskAssessment providerIds={selectedProviderIds} />),
                  disabled: isLoading || isFetching,
                },
              ]
            : undefined
        }
        {...props}
      >
        <Table<Provider>
          RowComponent={({ row }): JSX.Element => (
            <AssessmentRow
              row={row}
              onCheckboxChange={handleCheckboxChange}
              selectedProviders={selectedProviderIds}
              assessmentTemplatesIds={assessmentTemplates.map(template => Number(template.value))}
            />
          )}
          HeaderComponent={() => (
            <AssessmentHeader
              toggleSorting={toggleSorting}
              activeSorting={sorting}
              selectedProviders={selectedProviderIds}
              setSelectedProviders={setSelectedProviderIds}
              providersInPage={providers}
              assessmentTemplates={assessmentTemplates}
            />
          )}
          data={providers}
          count={count}
          isLoading={isLoading || isFetching}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
        />
      </ResourceCollectionScene>

      <UpdateProvidersDrawer
        selectedProviders={providers
          .filter(provider => selectedProviderIds.includes(provider.id))
          .map(provider => ({
            providerId: provider.id,
            name: provider.name,
            activityStatus: provider.activityStatus,
            finalRiskRating: provider.finalRiskRating,
            priority: provider.priority,
            providerApprovalStatus: provider.providerApprovalStatus,
            categoryOptionIds: provider.categoryOptions?.map(catOpt => catOpt.id),
            ownerUserId: provider.ownerUserId,
            tier: provider.tier,
            supplierUsage: provider.supplierUsage,
          }))}
        setSelectedProviders={setSelectedProviderIds}
        isDrawerOpen={isUpdateDrawerOpen}
        closeDrawer={closeUpdateDrawer}
      />
    </>
  )
}

export default AssessmentCompareScene
