import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAccessorQuestionGuidance from '@app/src/components/Drawer/Views/DrawerViewAccessorQuestionGuidance'
import EmptyState from '@app/src/components/EmptyState'
import { EmptyStateVariant } from '@app/src/components/EmptyState/EmptyState'
import TextField from '@app/src/components/Ui/TextField'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import AnswerClassificationPreviewCard from '@app/src/pages/QuestionEditor/AnswerClassification/AnswerClassificationPreviewCard'
import { QuestionInTemplate } from '@app/src/types/resourceExplorer'
import { Add, Edit, InfoOutlined, ListAltOutlined } from '@mui/icons-material'
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { AssessmentTemplateFormValues } from './CreateAssessmentFromQuestionnaire'
import { EditQuestionClassificationDialog } from './EditQuestionClasssificationDialog'
import { br } from '@app/src/utils/translationMarkup'

type AssessmentFormProps = {
  intervals: number
  questions?: QuestionInTemplate[]
  isPublished?: boolean
  onIntervalsChange: RadioGroupProps['onChange']
  disablePublishButton?: boolean
}

const AssessmentForm: React.FC<AssessmentFormProps> = ({
  intervals,
  questions,
  isPublished = false,
  onIntervalsChange,
  disablePublishButton = false,
}) => {
  const { formatMessage } = useIntl()
  const { register } = useFormContext<AssessmentTemplateFormValues>()
  const [isOpen, openDialog, closeDialog] = useDialogState()
  const [currentQuestion, setCurrentQuestion] = useState<QuestionInTemplate | undefined>()
  const { openDrawer } = useDrawer()

  const openQuestionClassificationDialog = (question: QuestionInTemplate) => {
    setCurrentQuestion(question)
    openDialog()
  }

  return (
    <Stack alignItems="center">
      <Stack component="form" sx={{ maxWidth: { md: '70%', sm: '100%' }, mr: { md: 0, sm: 3 } }} spacing={4}>
        <Stack spacing={3}>
          <TextField name="name" inputRef={register} label={formatMessage({ id: 'assessments.assessmentName' })} />
          <TextField
            name="description"
            inputRef={register}
            multiline
            minRows={3}
            label={formatMessage({ id: 'assessments.assessmentDescription' })}
          />
        </Stack>
        <Tooltip
          title={formatMessage({ id: 'assessments.unpublishToolTip' }, { br })}
          placement="bottom-start"
          disableHoverListener={!disablePublishButton}
        >
          <FormControlLabel
            control={<Switch color="primary" name="isPublished" defaultChecked={isPublished} inputRef={register()} />}
            labelPlacement="end"
            label={
              <FormLabel component="legend">{formatMessage({ id: 'assessments.availableForCustomers' })}</FormLabel>
            }
            sx={{ mb: 1 }}
            disabled={disablePublishButton}
          />
        </Tooltip>
        <Stack
          spacing={4}
          sx={{ borderRadius: 2, borderStyle: 'solid', borderWidth: 1, borderColor: 'grey.400', p: 4 }}
        >
          <Stack>
            <Typography variant="subtitle1" id="intervals-label">
              {formatMessage({ id: 'assessments.intervals' })}
            </Typography>
            <FormControl>
              <RadioGroup aria-labelledby="intervals-label" value={intervals} onChange={onIntervalsChange}>
                <Stack direction="row">
                  <FormControlLabel
                    value={3}
                    control={<Radio />}
                    label={formatMessage({ id: 'assessments.intervalsCount' }, { count: 3 })}
                  />
                  <FormControlLabel
                    value={5}
                    control={<Radio />}
                    label={formatMessage({ id: 'assessments.intervalsCount' }, { count: 5 })}
                  />
                </Stack>
              </RadioGroup>
            </FormControl>
          </Stack>
          {Array.from({ length: intervals }).map((_, index) => (
            <Stack key={index}>
              <Typography variant="subtitle1">
                {formatMessage({ id: `assessments.levels.${intervals}.level${index + 1}` })}
              </Typography>
              <TextField
                multiline
                minRows={3}
                name={`level${index + 1}Description`}
                inputRef={register}
                label={formatMessage({ id: 'general.description' })}
              />
            </Stack>
          ))}
        </Stack>
        <Stack
          spacing={4}
          sx={{ borderRadius: 2, borderStyle: 'solid', borderWidth: 1, borderColor: 'grey.400', p: 4 }}
        >
          <Box>
            <Typography variant="subtitle1">
              {formatMessage({ id: 'templateBuilder.answerClassifications.classification' })}
            </Typography>
            <Typography variant="caption">{formatMessage({ id: 'assessments.classificationInfo' })}</Typography>
          </Box>
          {questions?.map(q => (
            <Paper key={q.id} elevation={0} sx={{ p: 2 }}>
              <Stack direction="row" justifyContent="space-between" py={1} px={1}>
                <IconButton
                  onClick={() => openDrawer(<DrawerViewAccessorQuestionGuidance question={q} />, { anchor: 'left' })}
                >
                  <InfoOutlined />
                </IconButton>
                {Boolean(q.questionAnswerClassificationRules?.length) && (
                  <Box>
                    <IconButton onClick={() => openQuestionClassificationDialog(q)}>
                      <Edit />
                    </IconButton>
                  </Box>
                )}
              </Stack>
              <Typography variant="subtitle1" pt={2} pb={2} pl={1}>
                {q.kpiName || q.questionText}
              </Typography>
              {!q.questionAnswerClassificationRules || !q.questionAnswerClassificationRules.length ? (
                <Box p={3}>
                  <EmptyState
                    title={formatMessage({ id: 'assessments.noClassification' })}
                    iconComponent={ListAltOutlined}
                    variant={EmptyStateVariant.Small}
                  >
                    <Button startIcon={<Add />} variant="contained" onClick={() => openQuestionClassificationDialog(q)}>
                      {formatMessage({ id: 'assessments.addClassification' })}
                    </Button>
                  </EmptyState>
                </Box>
              ) : (
                <>
                  <AnswerClassificationPreviewCard
                    showTitle={false}
                    questionAnswerClassifications={q.questionAnswerClassificationRules}
                  />
                </>
              )}
            </Paper>
          ))}
        </Stack>
      </Stack>
      <EditQuestionClassificationDialog
        isOpen={isOpen}
        closeDialog={closeDialog}
        setCurrentQuestion={setCurrentQuestion}
        currentQuestion={currentQuestion}
      />
    </Stack>
  )
}

export default AssessmentForm
