import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost, useFetchResource } from '@app/src/api/fetchHooks'
import { useUpdateResource } from '@app/src/api/updateHooks'
import Permissions from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewAddMappingNode from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewAddMappingNode'
import DrawerViewAddValueChainForProduct from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewAddValueChainForProduct'
import DrawerViewEditMappingNode from '@app/src/components/Drawer/Views/ProductMapping/DrawerViewEditMappingNode'
import LoadingButton from '@app/src/components/LoadingButton'
import { useAccount } from '@app/src/context/AccountContext'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import { useQueryState } from '@app/src/hooks/queryState'
import useActorTypes from '@app/src/hooks/useActorTypes'
import useOrganizationSettings from '@app/src/hooks/useOrganizationSettings'
import StatisticsCard from '@app/src/pages/Dashboards/StatisticsCard'
import MappingRequestHistoryLog from '@app/src/pages/Product/MappingRequestHistoryLog'
import ProductDetails from '@app/src/pages/Product/ProductDetails'
import ProductForm from '@app/src/pages/Product/ProductForm'
import ProductHasConnectionsAlert from '@app/src/pages/Product/ProductHasConnectionsAlert'
import ProductRequestCard from '@app/src/pages/Product/ProductRequestCard/ProductRequestCard'
import ProductSceneContainer from '@app/src/pages/Product/ProductSceneContainer'
import ValueChainView from '@app/src/pages/Product/ValueChainView'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { Organization } from '@app/src/types/organizations'
import {
  MappingNode,
  Product,
  ProductFormData,
  ProductMappingRequest,
  ProductMappingResponse,
  ProductSceneView,
} from '@app/src/types/product'
import { NotificationSeverity } from '@app/src/wf-constants'
import paths from '@app/src/wf-constants/paths'
import { Add, East, Loop } from '@mui/icons-material'
import { Alert, AlertTitle, Box, Grid, Paper, Stack, Tooltip, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'
import { generatePath, useHistory, useParams } from 'react-router-dom'

interface ProductSceneProps {
  product: Product
}
export interface MappingResponseWithOrg {
  response: ProductMappingResponse
  responderOrganization: Organization
}

const ProductSceneWrapper: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const { data: product } = useFetchResource<Product>({
    endpoint: endpoints.product(id),
    key: [FetchKey.Product, Number(id)],
    options: {
      enabled: Boolean(id),
    },
  })
  if (!product) return null

  return <ProductScene product={product} />
}

const ProductScene: React.FC<ProductSceneProps> = ({ product }) => {
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()
  const history = useHistory()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const organizationSettings = useOrganizationSettings()
  const { account, hasPermission } = useAccount()

  const [view] = useQueryState<ProductSceneView>('view')

  const requestListRef = useRef<HTMLDivElement | null>(null)
  const [scroll, setScroll] = useState(false)
  const { openDrawer } = useDrawer()
  const formMethods = useForm<ProductFormData>({ defaultValues: { ...product, industry: product.industry } })
  const confirm = useConfirm()
  const mappedSuppliers = product.mappingNodes.filter(node => node.organizationId !== account?.organization?.id)

  const { items: productMappingRequests, isFetching } = useFetchCollectionWithPost<ProductMappingRequest>({
    endpoint: endpoints.productMappingRequests,
    key: FetchKey.MappingRequestsByProduct,
    payload: {
      filter: [
        {
          name: 'TargetProductId',
          filters: [
            {
              value: product.id,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: ['product.provider.organization', 'responderOrganization', 'productMappingResponses'],
    },
  })

  const { items: actorTypes } = useActorTypes({
    filter: [
      {
        name: 'industry',
        filters: [
          {
            value: product.industry,
            operator: Operators.EqualTo,
          },
        ],
      },
      {
        name: 'selectable',
        filters: [
          {
            value: true,
            operator: Operators.EqualTo,
          },
        ],
      },
    ],
    enabled: Boolean(product),
  })

  const { items: connectedMappingResponses } = useFetchCollectionWithPost<ProductMappingResponse>({
    endpoint: endpoints.productMappingResponseCollection,
    key: FetchKey.MappingResponseCollection,
    payload: {
      filter: [
        {
          name: 'product.id',
          filters: [
            {
              value: product?.id,
              operator: Operators.EqualTo,
            },
          ],
        },
      ],
      include: ['productMappingRequest.product', 'productMappingRequest.creatorOrganization'],
    },
    options: {
      enabled: Boolean(product?.id),
    },
  })

  const scrollToRequestList = (): void => {
    requestListRef.current?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (scroll && requestListRef.current && !isFetching) {
      scrollToRequestList()
      setScroll(false)
    }
  }, [scroll, requestListRef, productMappingRequests.length, isFetching])

  const { mutate: updateProduct, isLoading: isUpdatingProduct } = useUpdateResource<Product, Partial<Product>[]>({
    options: {
      onSuccess: () => {
        showSnackbar({
          message: formatMessage({ id: 'schemas.product.update' }),
          severity: NotificationSeverity.success,
        })
        history.push(generatePath(paths.product, { id: product?.id }))
        queryClient.invalidateQueries(FetchKey.Product)
      },

      onError: error => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const onSubmit = (values: ProductFormData) => {
    const { provider, ...rest } = values
    updateProduct({
      url: endpoints.updateProducts,
      body: [{ ...rest, providerId: provider?.id }],
    })
  }

  const latestMappingResponsesWithOrg = productMappingRequests?.reduce<MappingResponseWithOrg[]>(
    (acc, { productMappingResponses, responderOrganization }) => {
      if (!productMappingResponses?.[0]) return acc
      return [...acc, { response: productMappingResponses[0], responderOrganization }]
    },
    [],
  )

  const { count: availableMappingNodesCount } = useFetchCollectionWithPost<MappingNode>({
    key: FetchKey.MappingNodeCollection,
    endpoint: endpoints.mappingNodeCollection,
    payload: {
      filter: [
        {
          name: 'tier',
          filters: [{ value: '0', operator: Operators.NotEqualTo }],
        },
        {
          name: 'product.industry',
          filters: [{ value: product.industry, operator: Operators.EqualTo }],
        },
      ],
      include: [],
    },
  })

  const onSubmitProduct = () => {
    formMethods.handleSubmit(onSubmit)()
  }

  return (
    <ProductSceneContainer
      view={view}
      product={product}
      onCreateRequest={() => setScroll(true)}
      isUpdating={isUpdatingProduct}
      kpiCards={
        !hasPermission(Permissions.TRANSPARENCY_USER) ? (
          <Grid container spacing={2} mt={2}>
            <Grid item xs={3}>
              <StatisticsCard
                withBorder
                title={formatMessage({ id: 'schemas.product.supplyChainVisibility' })}
                justifyContent="left"
              >
                <Stack py={2} direction="row" alignItems="center" spacing={2}>
                  <Typography variant="kpi">
                    {formatMessage({ id: 'schemas.supplier.tierNumber' }, { number: mappedSuppliers?.[0]?.tier ?? 0 })}
                  </Typography>
                  {mappedSuppliers?.length > 1 && (
                    <>
                      <East />
                      <Typography variant="kpi">
                        {formatMessage(
                          { id: 'schemas.supplier.tierNumber' },
                          { number: mappedSuppliers?.[mappedSuppliers?.length - 1]?.tier },
                        )}
                      </Typography>
                    </>
                  )}
                </Stack>
              </StatisticsCard>
            </Grid>
            <Grid item xs={3}>
              <StatisticsCard
                withBorder
                title={formatMessage({ id: 'schemas.product.suppliersMapped' })}
                justifyContent="left"
              >
                <Typography py={2} variant="kpi">
                  {mappedSuppliers.length}
                </Typography>
              </StatisticsCard>
            </Grid>
            <Grid item xs={3}>
              <StatisticsCard
                withBorder
                title={formatMessage({ id: 'schemas.product.sentRequests' })}
                justifyContent="left"
              >
                <Typography py={2} variant="kpi">
                  {productMappingRequests.length}
                </Typography>
              </StatisticsCard>
            </Grid>
          </Grid>
        ) : undefined
      }
    >
      <Grid container spacing={5} px={4}>
        <Grid item xs={12} md={9}>
          <Paper elevation={0} sx={{ my: 4, p: 4 }}>
            {view === ProductSceneView.Edit ? (
              <>
                <Box mb={3}>
                  <Typography variant="h5" gutterBottom align="left">
                    {formatMessage({ id: 'schemas.product.productDetails' })}
                  </Typography>
                </Box>
                <FormProvider {...formMethods}>
                  <ProductForm
                    onCancel={() =>
                      history.push(
                        generatePath(paths.product, {
                          id: product?.id,
                        }),
                      )
                    }
                    isEdit
                    isSavingProduct={isUpdatingProduct}
                    onSubmitProduct={onSubmitProduct}
                  />
                </FormProvider>
              </>
            ) : (
              <ProductDetails view={view} product={product} />
            )}
          </Paper>
          {product && (
            <>
              <Paper elevation={0} sx={{ my: 4, p: 4 }}>
                <ValueChainView
                  product={product}
                  title={
                    <Typography variant="h5">{formatMessage({ id: 'schemas.product.yourSupplyChain' })}</Typography>
                  }
                  mappingNodes={product.mappingNodes}
                  isProductView
                  onEditNodeClick={mappingNode =>
                    openDrawer(
                      <DrawerViewEditMappingNode mappingNode={mappingNode} selectableActorTypes={actorTypes} />,
                    )
                  }
                  onAddNodeClick={product =>
                    openDrawer(<DrawerViewAddMappingNode product={product} selectableActorTypes={actorTypes} />)
                  }
                  showLastUpdate
                  responder={latestMappingResponsesWithOrg?.[0]?.responderOrganization}
                  autoAcceptValueChainEnabled={organizationSettings.get('autoAcceptValueChainResponses')}
                  connectedResponsesCount={connectedMappingResponses?.length}
                />

                <Box display="flex" justifyContent="center">
                  <Tooltip
                    title={
                      organizationSettings.get('autoAcceptValueChainResponses')
                        ? formatMessage({ id: 'schemas.mappingNode.disabledByAutomation' })
                        : undefined
                    }
                  >
                    <Box>
                      <LoadingButton
                        startIcon={<Add />}
                        variant="contained"
                        size="large"
                        disabled={organizationSettings.get('autoAcceptValueChainResponses')}
                        data-testid="add-supply-chain"
                        onClick={() => {
                          if (!connectedMappingResponses?.length) {
                            openDrawer(
                              <DrawerViewAddValueChainForProduct
                                product={product}
                                latestMappingResponsesWithOrg={latestMappingResponsesWithOrg}
                                selectableActorTypes={actorTypes}
                                hasAvailableMappingNodes={Boolean(availableMappingNodesCount)}
                              />,
                            )
                            return
                          }
                          confirm({
                            title: formatMessage({ id: 'general.areYouSure' }),
                            confirmationButtonProps: { variant: 'text' },
                            cancellationButtonProps: { variant: 'text' },
                            description: formatMessage({ id: 'schemas.mappingNode.connectedValueChainWarning' }),
                            cancellationText: formatMessage({ id: 'general.cancel' }),
                            confirmationText: formatMessage({ id: 'schemas.mappingNode.proceedWithModification' }),
                          }).then(() => {
                            openDrawer(
                              <DrawerViewAddValueChainForProduct
                                product={product}
                                latestMappingResponsesWithOrg={latestMappingResponsesWithOrg}
                                selectableActorTypes={actorTypes}
                                hasAvailableMappingNodes={Boolean(availableMappingNodesCount)}
                              />,
                            )
                          })
                        }}
                      >
                        {formatMessage({ id: 'schemas.mappingNode.add' })}
                      </LoadingButton>
                    </Box>
                  </Tooltip>
                </Box>
                {!organizationSettings.get('autoAcceptValueChainResponses') && (
                  <ProductHasConnectionsAlert connectedMappingResponses={connectedMappingResponses} product={product} />
                )}
              </Paper>
              {Boolean(productMappingRequests.length) && (
                <Paper elevation={0} sx={{ my: 4, p: 4 }} ref={requestListRef}>
                  <ProductRequestCard
                    latestMappingResponsesWithOrg={latestMappingResponsesWithOrg}
                    product={product}
                    mappingRequests={productMappingRequests}
                    disableValueChainReplacement={organizationSettings.get('autoAcceptValueChainResponses')}
                  />
                  {organizationSettings.get<boolean>('autoAcceptValueChainResponses') && (
                    <Alert sx={{ mb: 3 }} severity="info" icon={<Loop />}>
                      <AlertTitle sx={{ m: 0 }}>
                        {formatMessage({ id: 'automations.productMapping.acceptLatestValueChainsEnabledInfo.title' })}
                      </AlertTitle>
                    </Alert>
                  )}
                </Paper>
              )}
            </>
          )}
        </Grid>

        <Grid item sm={3} mt={5}>
          {product && <MappingRequestHistoryLog requests={productMappingRequests} product={product} />}
        </Grid>
      </Grid>
    </ProductSceneContainer>
  )
}

export default ProductSceneWrapper
