import TruncatedText from '@app/src/components/Ui/TruncatedText'
import { Box, ButtonBase, Paper, Typography } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import React from 'react'
import DataHubInsightsCardMenu from '../DataHub/DataInsights/DataHubInsightsCardMenu'

interface AssessmentGraphCardProps {
  children: React.ReactNode
  eChartsRef: React.MutableRefObject<ReactEChartsCore | null>
  onClickTitle: () => void
  chartSelector: React.ReactNode
  period?: number
}

const AssessmentGraphCard: React.FC<AssessmentGraphCardProps> = ({
  children,
  eChartsRef,
  onClickTitle,
  chartSelector,
  period,
}) => {
  return (
    <Paper
      elevation={0}
      variant="outlined"
      sx={({ palette }) => ({ padding: 2, outline: `1px solid ${palette.grey[300]}`, borderRadius: 2 })}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="start">
          <Box>
            <Box component={ButtonBase} onClick={onClickTitle}>
              <TruncatedText text="Assessment" line={1} variant="subtitle1" />
            </Box>
            <Typography variant="body1" color="text.secondary">
              {period}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="start" gap={1}>
          {chartSelector}
          <DataHubInsightsCardMenu echartsRef={eChartsRef} title="Assessment" />
        </Box>
      </Box>
      {children}
    </Paper>
  )
}

export default AssessmentGraphCard
