import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchResource } from '@app/src/api/fetchHooks'
import { usePatchUpdateResource } from '@app/src/api/updateHooks'
import ActionButtons, { ActionButton } from '@app/src/components/ActionButtons'
import ControlledLocationField, { SearchResult } from '@app/src/components/Form/ControlledLocationField'
import Select from '@app/src/components/Form/Select/ControlledSelect'
import TextField from '@app/src/components/Ui/TextField'
import { useSnackbar } from '@app/src/context/SnackbarContext'
import useErrorNotification from '@app/src/hooks/errorNotification'
import ConfigurationCollection from '@app/src/pages/Configurations/ConfigurationCollection'
import ConfigurationSkeleton from '@app/src/pages/Configurations/ConfigurationsSkeleton'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { CompanyRanges, NaceCode, Organization } from '@app/src/types/organizations'
import { validURL } from '@app/src/utils/helpers'
import { NotificationSeverity } from '@app/src/wf-constants'
import { Box, Grid, Stack, Typography } from '@mui/material'
import { useConfirm } from 'material-ui-confirm'
import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useQueryClient } from 'react-query'

interface OrganizationInfoFormProps {
  organization: Organization
}

const OrganizationInfoConfig: React.FC = () => {
  const {
    data: organization,
    isLoading,
    isFetching,
  } = useFetchResource<Organization>({
    endpoint: endpoints.getMyOrganization,
    key: FetchKey.GetMyOrganization,
  })

  if (isLoading || isFetching || !organization) return <ConfigurationSkeleton />
  return <OrganizationInfoForm organization={organization} />
}

const OrganizationInfoForm: React.FC<OrganizationInfoFormProps> = ({ organization }) => {
  const { showErrorNotification } = useErrorNotification()
  const queryClient = useQueryClient()
  const { showSnackbar } = useSnackbar()
  const formMethods = useForm<Organization>({ defaultValues: organization })
  const {
    register,
    handleSubmit,
    errors,
    control,
    watch,
    setValue,
    setError,
    formState: { isDirty },
  } = formMethods
  const { formatMessage } = useIntl()
  const [isEditing, setIsEditing] = useState(false)
  const confirm = useConfirm()

  const selectedIndustryId = watch('industryId')
  const locationCountryCodeWatch = watch('locations[0].countryCode')

  useEffect(() => {
    if (selectedIndustryId !== organization.industryId) {
      setValue('subIndustryId', undefined)
    }
  }, [selectedIndustryId])

  const { mutate: updateOrganization, isLoading: isUpdatingOrganization } = usePatchUpdateResource<
    Organization,
    Partial<Organization>
  >({
    options: {
      onSuccess: () => {
        showSnackbar({
          message: formatMessage({ id: 'schemas.organization.update' }),
          severity: NotificationSeverity.success,
        })
        queryClient.invalidateQueries(FetchKey.GetMyOrganization)
        queryClient.invalidateQueries(FetchKey.Account)
        setIsEditing(false)
      },

      onError: error => {
        showErrorNotification({ requestError: error, disableAutoClose: true })
      },
    },
  })

  const handleLocationValue = (searchResult: SearchResult) =>
    setValue('locations[0]', {
      address: searchResult.candidates[0].attributes.LongLabel,
      countryCode: searchResult.candidates[0].attributes.Country,
      region: searchResult.candidates[0].attributes.Region,
      subRegion: searchResult.candidates[0].attributes.Subregion,
      city: searchResult.candidates[0].attributes.City,
      district: searchResult.candidates[0].attributes.District,
      latitude: searchResult.candidates[0].location.y,
      longitude: searchResult.candidates[0].location.x,
    })

  const onSubmit = async (values: Partial<Organization>) => {
    if (!locationCountryCodeWatch) {
      setError('locations[0].address', {
        type: 'manual',
        message: formatMessage({ id: 'claim.createClaim.orgInfoStep.addressErrorMessage' }),
      })
      return
    }

    const payload = {
      ...values,
      id: organization.id,
    }
    updateOrganization({
      url: endpoints.updateOwnOrganization,
      body: payload,
    })
  }

  const cancelEdit = () => {
    if (isDirty) {
      confirm({
        hideCancelButton: false,
        confirmationText: formatMessage({ id: 'general.confirm' }),
        confirmationButtonProps: { variant: 'text' },
        cancellationButtonProps: { variant: 'text' },
        title: formatMessage({ id: 'general.youHaveUnsavedChanges' }),
        content: formatMessage({ id: 'general.exitWithoutSaving' }),
      })
        .then(() => {
          setIsEditing(false)
        })
        .catch(() => {
          // absorb error and stay on page
        })
    } else {
      setIsEditing(false)
    }
  }

  const getButtons = (): ActionButton[] => {
    if (isEditing) {
      return [
        {
          label: formatMessage({ id: 'general.cancel' }),
          variant: 'text',
          size: 'large',
          onClick: cancelEdit,
          loading: isUpdatingOrganization,
        },
        {
          label: formatMessage({ id: 'general.save' }),
          variant: 'contained',
          size: 'large',
          onClick: handleSubmit(onSubmit),
          loading: isUpdatingOrganization,
        },
      ]
    }

    return [
      {
        label: formatMessage({ id: 'general.edit' }),
        variant: 'contained',
        size: 'large',
        onClick: () => {
          setIsEditing(true)
        },
      },
    ]
  }

  return (
    <ConfigurationCollection actionButtons={getButtons()}>
      <FormProvider {...formMethods}>
        <form>
          <Box bgcolor="common.white" mr={4} p={3} borderRadius={1}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <Stack spacing={3}>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">
                        {`${formatMessage({ id: 'general.Organization' })} ${formatMessage({
                          id: 'schemas.organization.name',
                        }).toLowerCase()}`}
                      </Typography>
                    )}
                    {isEditing ? (
                      <TextField
                        fullWidth
                        hoveringLabel
                        name="name"
                        error={Boolean(errors?.name)}
                        label={`${formatMessage({ id: 'general.Organization' })} ${formatMessage({
                          id: 'schemas.organization.name',
                        }).toLowerCase()}`}
                        inputRef={register({ required: formatMessage({ id: 'form.validation.required' }) })}
                        helperText={errors?.name?.message}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization.name}
                      </Typography>
                    )}
                  </Stack>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">{formatMessage({ id: 'schemas.organization.address' })}</Typography>
                    )}
                    {isEditing ? (
                      <>
                        <ControlledLocationField
                          label={formatMessage({ id: 'schemas.organization.address' })}
                          locationName="locations[0]"
                          addressName="locations[0].address"
                          rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
                          valueSetter={handleLocationValue}
                          fieldErrors={errors?.locations?.[0]?.address}
                        />
                        <input ref={register} type="hidden" name="locations[0].countryCode" />
                        <input ref={register} type="hidden" name="locations[0].region" />
                        <input ref={register} type="hidden" name="locations[0].subRegion" />
                        <input ref={register} type="hidden" name="locations[0].city" />
                        <input ref={register} type="hidden" name="locations[0].district" />
                        <input ref={register} type="hidden" name="locations[0].latitude" />
                        <input ref={register} type="hidden" name="locations[0].longitude" />
                      </>
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization.locations?.[0]?.address || '-'}
                      </Typography>
                    )}
                  </Stack>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">
                        {formatMessage({ id: 'schemas.organization.websiteAddress' })}
                      </Typography>
                    )}
                    {isEditing ? (
                      <TextField
                        fullWidth
                        hoveringLabel
                        label={formatMessage({ id: 'schemas.organization.websiteAddress' })}
                        type="text"
                        name="websiteAddress"
                        inputRef={register({
                          required: formatMessage({ id: 'form.validation.required' }),
                          validate: (value: string): string | true =>
                            validURL(value) || formatMessage({ id: 'notifications.errorInvalidWebsite' }),
                        })}
                        required
                        error={Boolean(errors?.websiteAddress)}
                        helperText={errors?.websiteAddress?.message}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization.websiteAddress}
                      </Typography>
                    )}
                  </Stack>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">{formatMessage({ id: 'schemas.organization.industry' })}</Typography>
                    )}
                    {isEditing ? (
                      <Select<number, NaceCode>
                        name="industryId"
                        control={control}
                        hoveringLabel
                        required
                        forceFetch
                        error={errors?.industryId?.message}
                        rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
                        objectToOption={object => ({
                          label: object.description,
                          value: object.id,
                        })}
                        findSelectedValue={(value, option) => value === option.value}
                        navigation={{
                          url: endpoints.naceCodes,
                          type: 'post',
                          postObject: {
                            filter: [
                              {
                                name: 'parentId',
                                filters: [{ operator: Operators.IsNull }],
                              },
                            ],
                            include: [],
                            sort: { target: 'description', order: SortOrder.ASCENDING },
                          },
                        }}
                        fieldLabel={formatMessage({ id: 'schemas.organization.industry' })}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization.industryNaceCode?.description || '-'}
                      </Typography>
                    )}
                  </Stack>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">
                        {formatMessage({ id: 'schemas.organization.subIndustry' })}
                      </Typography>
                    )}
                    {isEditing ? (
                      <Select<number, NaceCode>
                        name="subIndustryId"
                        control={control}
                        hoveringLabel
                        required
                        forceFetch={Boolean(selectedIndustryId)}
                        error={errors?.subIndustryId?.message}
                        rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
                        disabled={!selectedIndustryId}
                        objectToOption={object => ({
                          label: object.description,
                          value: object.id,
                        })}
                        findSelectedValue={(value, option) => value === option.value}
                        navigation={{
                          url: endpoints.naceCodes,
                          type: 'post',
                          postObject: {
                            filter: [
                              {
                                name: 'parentId',
                                filters: [{ operator: Operators.EqualTo, value: selectedIndustryId }],
                              },
                            ],
                            include: [],
                            sort: { target: 'description', order: SortOrder.ASCENDING },
                          },
                        }}
                        fieldLabel={formatMessage({ id: 'schemas.organization.subIndustry' })}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization.subIndustryNaceCode?.description || '-'}
                      </Typography>
                    )}
                  </Stack>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">{formatMessage({ id: 'schemas.organization.employees' })}</Typography>
                    )}
                    {isEditing ? (
                      <Select
                        fieldLabel={formatMessage({ id: 'schemas.organization.employees' })}
                        name="companySizeRange"
                        hoveringLabel
                        control={control}
                        required
                        error={errors?.companySizeRange?.message}
                        rules={{ required: formatMessage({ id: 'form.validation.required' }) }}
                        options={Object.values(CompanyRanges).map(range => ({
                          label: formatMessage({ id: `claim.companySize.${range}` }),
                          value: range,
                        }))}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization?.companySizeRange
                          ? formatMessage({ id: `claim.companySize.${organization.companySizeRange}` })
                          : '-'}
                      </Typography>
                    )}
                  </Stack>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">{formatMessage({ id: 'schemas.organization.revenue' })}</Typography>
                    )}
                    {isEditing ? (
                      <Select
                        fieldLabel={formatMessage({ id: 'schemas.organization.revenue' })}
                        hoveringLabel
                        name="revenueRange"
                        control={control}
                        error={errors?.revenueRange?.message}
                        options={Object.values(CompanyRanges).map(range => ({
                          label: formatMessage({ id: `claim.companyRevenue.${range}` }),
                          value: range,
                        }))}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization?.revenueRange
                          ? formatMessage({ id: `claim.companyRevenue.${organization.revenueRange}` })
                          : '-'}
                      </Typography>
                    )}
                  </Stack>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">{formatMessage({ id: 'schemas.organization.vatNumber' })}</Typography>
                    )}
                    {isEditing ? (
                      <TextField
                        fullWidth
                        hoveringLabel
                        name="vatNumber"
                        label={formatMessage({ id: 'schemas.organization.vatNumber' })}
                        inputRef={register}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization.vatNumber || '-'}
                      </Typography>
                    )}
                  </Stack>
                  <Stack>
                    {!isEditing && (
                      <Typography variant="body2">
                        {formatMessage({ id: 'schemas.organization.registrationNumber' })}
                      </Typography>
                    )}

                    {isEditing ? (
                      <TextField
                        fullWidth
                        hoveringLabel
                        name="registrationNumber"
                        label={formatMessage({ id: 'schemas.organization.registrationNumber' })}
                        inputRef={register}
                      />
                    ) : (
                      <Typography variant="body2" color="textSecondary">
                        {organization.registrationNumber || '-'}
                      </Typography>
                    )}
                  </Stack>
                  {isEditing && (
                    <Stack flexDirection="row" justifyContent="flex-end">
                      <ActionButtons buttons={getButtons()} />
                    </Stack>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </form>
      </FormProvider>
    </ConfigurationCollection>
  )
}

export default OrganizationInfoConfig
