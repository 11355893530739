import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import Table from '@app/src/components/Table'
import usePagination from '@app/src/hooks/pagination'
import useSort from '@app/src/hooks/sorting'
import Assessment from '@app/src/types/assessment'
import { AssessmentTemplate } from '@app/src/types/resourceExplorer'
import { insertIf } from '@app/src/utils/helpersTs'
import React from 'react'
import { FilterGroup, Operators } from '../../../Filters/useFilters'
import AssessmentDialogHeader from '../../Assessments/AssessmentDialogHeader'
import AssessmentDialogRow from '../../Assessments/AssessmentDialogRow'

type AssessmentModalTableProps = {
  assessmentTemplate: AssessmentTemplate
  activeLevel?: number
}

const AssessmentModalTable: React.FC<AssessmentModalTableProps> = ({ assessmentTemplate, activeLevel }) => {
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize] = usePagination()

  const {
    items: assessments,
    isLoading,
    count,
    isError,
  } = useFetchCollectionWithPost<Assessment>({
    key: FetchKey.AssessmentCollection,
    endpoint: endpoints.assessmentCollection,
    payload: {
      filter: [
        {
          name: 'assessmentTemplateId',
          filters: [
            {
              operator: Operators.EqualTo,
              value: assessmentTemplate.id,
            },
          ],
        },
        ...insertIf<FilterGroup>(Boolean(activeLevel), {
          name: 'totalLevel',
          filters: [
            {
              operator: Operators.EqualTo,
              value: activeLevel,
            },
          ],
        }),
      ],
      include: ['provider', 'assessmentTemplate'],
    },
  })

  return (
    <>
      <Table<Assessment>
        HeaderComponent={() => (
          <AssessmentDialogHeader
            toggleSorting={toggleSorting}
            activeSorting={sorting}
            assessmentTemplate={assessmentTemplate}
          />
        )}
        RowComponent={({ row }) => <AssessmentDialogRow row={row} />}
        data={assessments}
        isLoading={isLoading}
        count={count}
        isError={isError}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
      />
    </>
  )
}

export default AssessmentModalTable
