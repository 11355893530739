import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchPost, useFetchResource } from '@app/src/api/fetchHooks'
import Permissions from '@app/src/auth/permissions'
import { useAccount } from '@app/src/context/AccountContext'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import useFeatureFlagsByAccessor from '@app/src/hooks/useFeatureFlagsByAccessor'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { Collection, RequestItem } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import { useMemo } from 'react'

export interface AiSuggestion {
  response: string
  selectedOptions?: string[]
  citations: string[]
}

type UseFetchAiSuggestions = {
  fetchedData?: AiSuggestion
  isLoadingAiSuggestions: boolean
}

type PayloadType = {
  organizationName?: string
  periodName?: string
  requestItem: {
    id: number
    description: string
    allowMultiChoice?: boolean
    unit: { symbol?: string | null }
    requestCollection: {
      id: number
      items: Collection[]
    }
  }
}

const useFetchAiSuggestions = (
  requestItem: RequestItem,
  questionType: string,
  isPreview: boolean,
  periodName?: string,
): UseFetchAiSuggestions => {
  const { account, hasPermission } = useAccount()
  const { isResponseFetching, response, showPreviousAnswerSuggestions } = useReport()
  const { orgOrAccessorHasFeature: suggestedAnswers } = useFeatureFlagsByAccessor({
    featureToggleName: 'suggested-answers',
    enabled: hasPermission(Permissions.TRANSPARENCY_USER),
  })
  const { hideInputField, previousAnswer } = useResponseItemContext()

  const isFileQuestion = questionType?.toLowerCase() === QuestionTypes.File.toLowerCase()
  const { data: files, isLoading: isFilesLoading } = useFetchResource<{ name: string; url: string }[]>({
    endpoint: endpoints.suggestionFiles,
    key: FetchKey.FileRepository,
    options: {
      refetchOnMount: false,
      enabled: suggestedAnswers && !isFileQuestion && !isPreview,
    },
  })

  const shouldShowSuggestion = useMemo(() => {
    if (isFilesLoading || !files?.length) return false
    if (!suggestedAnswers) return false
    if (isResponseFetching) return false
    if (hideInputField) return false
    if (previousAnswer && showPreviousAnswerSuggestions) return false
    const responseItem = response?.items?.find(it => it.requestItemId === requestItem.id)
    return Boolean(responseItem && !responseItem.isAnswered)
  }, [
    isResponseFetching,
    suggestedAnswers,
    isFilesLoading,
    hideInputField,
    Boolean(previousAnswer),
    showPreviousAnswerSuggestions,
  ])

  const { data: fetchedData, isLoading } = useFetchPost<AiSuggestion, PayloadType>({
    key: [FetchKey.SuggestedResponse, requestItem.id],
    endpoint: endpoints.suggestedResponse(questionType),
    payload: {
      organizationName: account?.organization?.name,
      periodName: periodName,
      requestItem: {
        id: requestItem.id,
        requestCollection: requestItem.requestCollection,
        allowMultiChoice: requestItem.allowMultiChoice,
        unit: requestItem.unit,
        description: requestItem.description,
      },
    },
    options: {
      enabled: shouldShowSuggestion && Boolean(requestItem.description) && !isPreview && !isFileQuestion,
      retry: false,
      refetchOnMount: false,
    },
  })

  return { fetchedData, isLoadingAiSuggestions: isLoading }
}

export default useFetchAiSuggestions
