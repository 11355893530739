import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchFacets } from '@app/src/api/fetchHooks'
import EmptyState from '@app/src/components/EmptyState'
import LinkButton from '@app/src/components/LinkButton'
import { useDialogState } from '@app/src/hooks/mui-hooks'
import { AssessmentTemplate, AssessmentType } from '@app/src/types/resourceExplorer'
import paths from '@app/src/wf-constants/paths'
import { BarChartOutlined } from '@mui/icons-material'
import { Box, Chip, MenuItem, Skeleton, Stack, TextField, Typography, useTheme } from '@mui/material'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { generatePath } from 'react-router'
import { FacetItem } from '../..'
import { Operators } from '../../Filters/useFilters'
import { ViewTypeName } from '../ManageRequests/ManageRequestsScene'
import AssessmentDialog from './AssessmentDialog'
import AssessmentGraphCard from './AssessmentGraphCard'
import ChartTypeSelector, { ChartType } from './ChartTypeSelector'
import useAssessmentOverviewGraph from './useAssessmentOverviewGraph'

type AssessmentOverviewGraphProps = {
  assessmentTemplate: AssessmentTemplate
}

const AssessmentOverviewGraph: React.FC<AssessmentOverviewGraphProps> = ({ assessmentTemplate }) => {
  const eChartsRef = React.useRef<null | ReactEChartsCore>(null)
  const { spacing } = useTheme()
  const { formatMessage } = useIntl()
  const [selectedChartType, setSelectedChartType] = useState<ChartType>(ChartType.Horizontal)
  const [activePeriod, setActivePeriod] = useState<number>()
  const [assessmentDialogOpen, openAssessmentDialog, closeAssessmentDialog] = useDialogState()
  const [activeLevel, setActiveLevel] = useState<number>()
  const isBaselineAssessment = assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment

  const {
    facets: [periodFacets = []],
    isLoading: isLoadingPeriod,
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'periods'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'periodName', isEnum: true }],
    filter: [
      { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
    ],
  })

  const {
    facets: [levelFacets = []],
    isLoading: isLoadingLevels,
  } = useFetchFacets({
    key: [FetchKey.AssessmentFacets, 'levels'],
    endpoint: endpoints.assessmentFacet,
    facetsParam: [{ name: 'totalLevel', isEnum: true }],
    filter: [
      { name: 'assessmentTemplateId', filters: [{ value: assessmentTemplate.id, operator: Operators.EqualTo }] },
      { name: 'periodName', filters: [{ value: activePeriod?.toString(), operator: Operators.EqualTo }] },
    ],
    options: {
      enabled: Boolean(activePeriod),
    },
  })

  const levels = useMemo<Record<number, FacetItem | undefined>>(
    () => ({
      1: levelFacets.find(l => l.value === 1),
      2: levelFacets.find(l => l.value === 2),
      3: levelFacets.find(l => l.value === 3),
      4: levelFacets.find(l => l.value === 4),
      5: levelFacets.find(l => l.value === 5),
    }),
    [levelFacets],
  )

  const totalSuppliersThatResponded = useMemo(
    () => Object.values(levels)?.reduce((acc, level) => acc + (level?.count ?? 0), 0) ?? 0,
    [levels],
  )
  const has5Levels = assessmentTemplate.levels !== 3 // null or 5 shows 5 levels to be backward compatible

  const levelChartObj = useMemo(
    () =>
      Array.from(Array(assessmentTemplate.levels ?? 5).keys()).map(i => {
        const level = levelFacets.find(l => l.value === i + 1)
        return {
          value: Number(level?.count ?? 0),
          level: i + 1,
        }
      }),
    [assessmentTemplate, levelFacets],
  )

  const options = useAssessmentOverviewGraph(levelChartObj, selectedChartType, has5Levels, isBaselineAssessment)

  const events = useMemo(
    () => ({
      click: ({ data }: { data: { level: number; value: number } }) => {
        if (!data.value) {
          return
        }

        setActiveLevel(data.level)
        openAssessmentDialog()
      },
    }),
    [],
  )

  const handleCloseDialog = () => {
    closeAssessmentDialog()
    setActiveLevel(undefined)
  }

  useEffect(() => {
    if (periodFacets?.[0]?.value) {
      setActivePeriod(Number(periodFacets?.[0]?.value))
    }
  }, [periodFacets])

  if (isLoadingLevels || isLoadingPeriod) {
    return (
      <Stack bgcolor="grey.200" borderRadius={1} p={4} gap={4} minHeight={600} height="100%" flexGrow={1}>
        <Typography variant="h2">{assessmentTemplate.name}</Typography>

        <Stack flexGrow={1} alignItems="flex-end" justifyContent="stretch" width="100%">
          <Skeleton variant="rounded" width="100%" height={470} />
        </Stack>
      </Stack>
    )
  }

  if (!levelFacets.length) {
    return (
      <Stack bgcolor="grey.200" borderRadius={1} p={4} gap={4} minHeight={600} height="100%" flexGrow={1}>
        <Typography variant="h2">{assessmentTemplate.name}</Typography>
        <Stack alignItems="center" justifyContent="center" flexGrow={1} p={3}>
          <EmptyState
            iconComponent={BarChartOutlined}
            title="No data for the current period"
            description="Request data for the current period or  switch to another period to analyze previous results"
          >
            <LinkButton variant="outlined" to={generatePath(paths.manageRequest, { view: ViewTypeName.Requests })}>
              {formatMessage({ id: 'assessments.requestData' })}
            </LinkButton>
          </EmptyState>
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack bgcolor="common.white" borderRadius={1} p={4} gap={4} height="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="h2">{assessmentTemplate.name}</Typography>

        {Boolean(periodFacets.length) && (
          <Stack direction="row" ml={2}>
            <Typography color="primary" variant="button" mr={1}>
              {formatMessage({ id: 'reporting.period' })}
            </Typography>

            <TextField
              select
              size="small"
              variant="standard"
              onChange={e => setActivePeriod(Number(e.target.value))}
              value={activePeriod ?? ''}
              disabled={isLoadingPeriod}
            >
              {periodFacets?.map(period => (
                <MenuItem key={period.value.toString()} value={period.value.toString()}>
                  <Chip label={period.label} size="small" color="default" />
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        )}
      </Stack>

      {Boolean(levelFacets.length) && (
        <Stack>
          <Typography>{formatMessage({ id: 'assessments.suppliersAssessed' })}</Typography>
          <Typography variant="subtitle1">{totalSuppliersThatResponded}</Typography>
        </Stack>
      )}

      {isLoadingPeriod || isLoadingLevels ? (
        <Skeleton height={470} />
      ) : (
        <Stack justifyContent="flex-end" flexGrow={1}>
          <AssessmentGraphCard
            onClickTitle={openAssessmentDialog}
            eChartsRef={eChartsRef}
            period={activePeriod}
            chartSelector={
              <ChartTypeSelector
                selectedChartType={selectedChartType}
                setSelectedChartType={setSelectedChartType}
                allowedChartTypes={[ChartType.Pie, ChartType.Horizontal, ChartType.Vertical]}
              />
            }
          >
            <Box height={spacing(48)} display="flex" flexDirection="column">
              <ReactEChartsCore
                onEvents={events}
                option={options}
                echarts={echarts}
                style={{ minWidth: '100%', flexGrow: 1 }}
                ref={eChartsRef}
                notMerge
              />
            </Box>
          </AssessmentGraphCard>
        </Stack>
      )}

      <AssessmentDialog
        isOpen={assessmentDialogOpen}
        closeModal={handleCloseDialog}
        assessmentTemplate={assessmentTemplate}
        period={activePeriod}
        activeLevel={activeLevel}
      />
    </Stack>
  )
}

export default AssessmentOverviewGraph
