import { ChartType } from '@app/src/pages/ResourceCollection/Collections/Assessments/ChartTypeSelector'
import { useTheme } from '@mui/material'
import { EChartsOption } from 'echarts-for-react'
import { XAXisOption, YAXisOption } from 'echarts/types/dist/shared'
import { IntlFormatters, useIntl } from 'react-intl'

export type AssessmentOverViewGraphDatapoint = {
  value: number
  level: string
  name: string
}

const getTranslationKeysForLevels = (
  isBaselineAssessment: boolean,
  has5Levels: boolean,
  formatMessage: IntlFormatters['formatMessage'],
) => {
  if (isBaselineAssessment) {
    return Array.from(Array(5).keys()).map(i => formatMessage({ id: 'baseAssessment.totalLevel' }, { value: i + 1 }))
  }

  if (has5Levels) {
    return Array.from(Array(5).keys()).map(i => formatMessage({ id: `assessments.levels.5.level${i + 1}` }))
  }

  return Array.from(Array(3).keys()).map(i => formatMessage({ id: `assessments.levels.3.level${i + 1}` }))
}

const getAxisForSelectedChartType = (selectedChartType: ChartType): { xAxis?: XAXisOption; yAxis?: YAXisOption } => {
  if (selectedChartType === ChartType.Horizontal) {
    return {
      xAxis: {
        type: 'value',
        name: 'Number of suppliers',
        position: 'bottom',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: 10,
        },
        minInterval: 1,
      },
      yAxis: {
        type: 'category',
        show: false,
      },
    }
  }
  if (selectedChartType === ChartType.Vertical)
    return {
      xAxis: {
        type: 'category',
        show: false,
      },
      yAxis: {
        type: 'value',
        name: 'Number of suppliers',
        position: 'bottom',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: 10,
        },
        minInterval: 1,
      },
    }

  return {
    xAxis: {
      show: false,
    },
    yAxis: {
      show: false,
    },
  }
}

const useAssessmentOverviewGraph = (
  levels: Array<{
    value: number
    level: number
  }>,
  selectedChartType: ChartType,
  has5Levels: boolean,
  isBaselineAssessment: boolean,
): EChartsOption => {
  const { formatMessage } = useIntl()
  const { palette, typography } = useTheme()

  const COLORS = has5Levels
    ? [
        palette.semantic.error,
        palette.error.dark,
        palette.warning.dark,
        palette.success.dark,
        palette.brandDecorative.emerald,
      ]
    : [palette.error.dark, palette.warning.dark, palette.success.dark]

  const labelSeries = getTranslationKeysForLevels(isBaselineAssessment, has5Levels, formatMessage)
  const mappedLevels = levels.map(level => ({
    ...level,
    label: labelSeries[Number(level?.level) - 1],
  }))

  switch (selectedChartType) {
    case ChartType.Pie:
      return {
        grid: {
          left: 10,
          right: 20,
          top: 10,
          bottom: 50,
        },
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        legend: {
          icon: 'circle',
          bottom: 0,
          left: 0,
          selectedMode: false,
          textStyle: {
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
          },
        },
        color: COLORS,
        series: [
          {
            type: 'pie',
            bottom: 50,
            data: mappedLevels.map(i => ({
              value: i.value,
              name: i.label,
              level: i.level,
            })),
          },
        ],
      }
    case ChartType.Horizontal:
    case ChartType.Vertical:
      return {
        ...getAxisForSelectedChartType(selectedChartType),
        grid: {
          left: selectedChartType === ChartType.Horizontal ? 10 : 50,
          right: 20,
          top: selectedChartType === ChartType.Horizontal ? 10 : 50,
          bottom: selectedChartType === ChartType.Horizontal ? 70 : 50,
        },
        tooltip: {
          trigger: 'item',
          confine: true,
        },
        legend: {
          data: mappedLevels.map(i => ({ name: i?.label, icon: 'circle' })),
          bottom: 0,
          left: 0,
          selectedMode: false,
          textStyle: {
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
          },
        },
        color: COLORS,
        series: mappedLevels.map(i => ({
          type: 'bar',
          name: i.label,
          data: [
            {
              value: i.value,
              name: i.label,
              level: i.level,
            },
          ],
          bottom: 50,
          label: {
            show: true,
            position: selectedChartType === ChartType.Horizontal ? 'right' : 'top',
            distance: 10,
            fontFamily: typography.fontFamily,
            fontSize: typography.body2.fontSize,
          },
        })),
      }
  }
}

export default useAssessmentOverviewGraph
