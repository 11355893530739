import endpoints from '@app/src/api/endpoints'
import { FetchKey } from '@app/src/api/fetchHooks'
import Permissions, { usePermissions } from '@app/src/auth/permissions'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewFilters from '@app/src/components/Drawer/Views/DrawerViewFilters'
import { OptionIcon } from '@app/src/components/Form/Select'
import { useAutocompleteStyles } from '@app/src/components/Form/Select/SimpleSelect'
import { FinalRiskRatingIcon } from '@app/src/components/Table/Cells/FinalRiskRatingCell'
import { useAuthentication } from '@app/src/context/AuthenticationContext'
import Filter from '@app/src/pages/ResourceCollection/Filters/Filter'
import FilterFacetSelect from '@app/src/pages/ResourceCollection/Filters/FilterFacetSelect'
import Filters from '@app/src/pages/ResourceCollection/Filters/Filters'
import { Operators } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import { SortOrder } from '@app/src/types/filter'
import { Solutions } from '@app/src/wf-constants'
import { Box, Button, Chip, createFilterOptions, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { FacetItem, FiltersProps } from '..'
import AllFiltersButton from './AllFiltersButton'
import CustomCategoriesFilters from './CustomCategoriesFilters'
import FilterSection from './FilterSection'
import OptionWithLabel from './OptionWithLabel'
import PeriodTypeOption from './PeriodTypeOption'
import RangeFilter from './RangeFilter'
import SpendFilter from './SpendFilter'
import StatusOption from './StatusOption'

const RequestsFilters = ({ allowedFilters, implicitFilters, resetPage }: FiltersProps): JSX.Element => {
  const { renderWithPermission } = usePermissions()
  const autocompleteClasses = useAutocompleteStyles()
  const { formatMessage } = useIntl()
  const { openDrawer } = useDrawer()

  const { hasPermission } = usePermissions()
  const verificationEnabled = hasPermission(Permissions.WRITE_VERIFICATION)
  const { solution } = useAuthentication().scope

  const tierFilter = createFilterOptions<FacetItem>({
    stringify: option =>
      formatMessage(
        {
          id: `schemas.provider.tierValues`,
        },
        { tier: option.value },
      ),
  })

  return (
    <Grid container spacing={1}>
      <Filters allowedFilters={allowedFilters} resetPage={resetPage}>
        {({ clearQueryFilter, inDrawerFiltersCount, isFiltered }) => (
          <>
            <Grid item md={5} sm={10}>
              <Filter name="template.id" operator={Operators.In}>
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.RequestFacets, filterName],
                      endpoint: endpoints.inquiryWithFacets,
                      facetsParam: [{ name: 'template.title' }],
                      sort: { target: 'label', order: SortOrder.ASCENDING },
                    }}
                    filterName={filterName}
                    onChange={onChange}
                    fieldLabel={formatMessage({ id: 'schemas.filter.requestName' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Grid>
            <Grid item md={2}>
              <Filter name="status" operator={Operators.In}>
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    size="small"
                    multiple
                    facetsParam={{
                      key: [FetchKey.RequestFacets, filterName],
                      endpoint: endpoints.inquiryWithFacets,
                      facetsParam: [{ name: filterName }],
                    }}
                    renderOption={(props, option, { selected }): JSX.Element => (
                      <StatusOption props={props} option={option} selected={selected} />
                    )}
                    renderTags={(value, getTagProps) =>
                      value?.map(
                        (option, index) =>
                          option && (
                            <Tooltip
                              key={option?.value?.toString() || index}
                              title={formatMessage({ id: `reporting.statuses.${option.label}` })}
                              classes={{ tooltipPlacementBottom: autocompleteClasses.tooltip }}
                              arrow
                            >
                              <Chip
                                label={formatMessage({ id: `reporting.statuses.${option.label}` })}
                                size="small"
                                {...getTagProps({ index })}
                              />
                            </Tooltip>
                          ),
                      )
                    }
                    filterName={filterName}
                    onChange={value => onChange(value)}
                    fieldLabel={formatMessage({ id: 'schemas.filter.IncludeRequestStatus' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Grid>
            <Grid item md={2}>
              <Filter name="periodName" operator={Operators.In}>
                {({ value, onChange, filterName }) => (
                  <FilterFacetSelect
                    implicitFilters={implicitFilters}
                    multiple
                    facetsParam={{
                      key: [FetchKey.RequestFacets, filterName],
                      endpoint: endpoints.inquiryWithFacets,
                      facetsParam: [{ name: filterName, isEnum: true }],
                      sort: { target: 'label', order: SortOrder.DESCENDING },
                    }}
                    filterName={filterName}
                    onChange={value => onChange(value)}
                    fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                    value={[value].flat()}
                  />
                )}
              </Filter>
            </Grid>
            <Grid item md={3}>
              <Box display="inline-block">
                <AllFiltersButton
                  inDrawerFiltersCount={inDrawerFiltersCount}
                  openDrawer={() =>
                    openDrawer(
                      <DrawerViewFilters allowedFilters={allowedFilters}>
                        <Box px={2}>
                          <FilterSection label={formatMessage({ id: 'schemas.filter.requests' })}>
                            <Filter name="template.id" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  implicitFilters={implicitFilters}
                                  size="small"
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.RequestFacets, filterName],
                                    endpoint: endpoints.inquiryWithFacets,
                                    facetsParam: [{ name: 'template.title' }],
                                  }}
                                  filterName={filterName}
                                  onChange={onChange}
                                  fieldLabel={formatMessage({ id: 'schemas.filter.requestName' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>

                            <Filter name="status" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  implicitFilters={implicitFilters}
                                  size="small"
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.RequestFacets, filterName],
                                    endpoint: endpoints.inquiryWithFacets,
                                    facetsParam: [{ name: filterName }],
                                  }}
                                  renderOption={(props, option, { selected }): JSX.Element => (
                                    <StatusOption props={props} option={option} selected={selected} />
                                  )}
                                  renderTags={(value, getTagProps) =>
                                    value?.map(
                                      (option, index) =>
                                        option && (
                                          <Tooltip
                                            key={option?.value?.toString() || index}
                                            title={formatMessage({ id: `reporting.statuses.${option.label}` })}
                                            classes={{ tooltipPlacementBottom: autocompleteClasses.tooltip }}
                                            arrow
                                          >
                                            <Chip
                                              label={formatMessage({
                                                id: `reporting.statuses.${option.label}`,
                                              })}
                                              size="small"
                                              {...getTagProps({ index })}
                                            />
                                          </Tooltip>
                                        ),
                                    )
                                  }
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.filter.IncludeRequestStatus' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>

                            <Filter name="periodName" operator={Operators.In}>
                              {({ value, onChange, filterName }) => (
                                <FilterFacetSelect
                                  implicitFilters={implicitFilters}
                                  size="small"
                                  multiple
                                  facetsParam={{
                                    key: [FetchKey.RequestFacets, filterName],
                                    endpoint: endpoints.inquiryWithFacets,
                                    facetsParam: [{ name: filterName, isEnum: true }],
                                  }}
                                  filterName={filterName}
                                  onChange={value => onChange(value)}
                                  fieldLabel={formatMessage({ id: 'schemas.request.period' })}
                                  value={[value].flat()}
                                />
                              )}
                            </Filter>
                          </FilterSection>
                          <FilterSection label={formatMessage({ id: 'schemas.request.requestTargetInformation' })}>
                            <Box mb={2}>
                              <Filter operator={Operators.In} name="provider.websiteAddress">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'provider.websiteAddress', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.websiteAddress' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </Box>
                            <Box mb={2}>
                              <Filter name="provider.country.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'provider.country.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.country.countryCode' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </Box>
                            <Box mb={2}>
                              <Filter operator={Operators.In} name="provider.vatNumber">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'provider.vatNumber', isEnum: true }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.vatNumber' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </Box>
                            <Box mb={2}>
                              <Filter name="provider.organization.id" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'provider.organization.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.filter.companyName' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </Box>
                            <Box>
                              <SpendFilter spendFilterNamePrefix="provider" allowedFilters={allowedFilters} />
                            </Box>
                          </FilterSection>

                          {verificationEnabled && (
                            <FilterSection label={formatMessage({ id: 'schemas.request.requestVerification' })}>
                              <Box mb={2}>
                                <Filter name="request.responseInternalStatus" operator={Operators.In}>
                                  {({ value, onChange, filterName }) => (
                                    <FilterFacetSelect
                                      implicitFilters={implicitFilters}
                                      size="small"
                                      multiple
                                      facetsParam={{
                                        key: [FetchKey.RequestFacets, filterName],
                                        endpoint: endpoints.inquiryWithFacets,
                                        facetsParam: [{ name: filterName }],
                                      }}
                                      renderOption={(props, option, { selected }): JSX.Element => (
                                        <StatusOption props={props} option={option} selected={selected} verification />
                                      )}
                                      filterName={filterName}
                                      onChange={value => onChange(value)}
                                      fieldLabel={formatMessage({
                                        id: 'schemas.request.responseInternalStatus',
                                      })}
                                      value={[value].flat()}
                                      renderTags={(value, getTagProps) =>
                                        value?.map(
                                          (option, index) =>
                                            option && (
                                              <Tooltip
                                                key={option?.value?.toString() || index}
                                                title={formatMessage({
                                                  id: `reporting.internalVerificationStatuses.${option.label}`,
                                                })}
                                                classes={{
                                                  tooltipPlacementBottom: autocompleteClasses.tooltip,
                                                }}
                                                arrow
                                              >
                                                <Chip
                                                  label={formatMessage({
                                                    id: `reporting.internalVerificationStatuses.${option.label}`,
                                                  })}
                                                  size="small"
                                                  {...getTagProps({ index })}
                                                />
                                              </Tooltip>
                                            ),
                                        )
                                      }
                                      skipNotSet={false}
                                    />
                                  )}
                                </Filter>
                              </Box>
                            </FilterSection>
                          )}
                          <FilterSection label={formatMessage({ id: 'schemas.request.requestTimeline' })}>
                            <RangeFilter
                              uniqueIdFrom="deadlineFrom"
                              uniqueIdTo="deadlineTo"
                              name="deadline"
                              fieldLabelFrom={formatMessage({ id: 'schemas.filter.deadlineFrom' })}
                              fieldLabelTo={formatMessage({ id: 'schemas.filter.deadlineTo' })}
                            />

                            <Typography variant="subtitle1">
                              {formatMessage({ id: 'schemas.request.created' })}
                            </Typography>
                            <RangeFilter
                              uniqueIdFrom="createdFrom"
                              uniqueIdTo="createdTo"
                              name="createdAt"
                              fieldLabelFrom={formatMessage({ id: 'schemas.filter.createdFrom' })}
                              fieldLabelTo={formatMessage({ id: 'schemas.filter.createdTo' })}
                            />
                            <Typography variant="subtitle1">
                              {formatMessage({ id: 'schemas.request.scheduled' })}
                            </Typography>

                            <Box mb={2}>
                              <Filter name="periodType" operator={Operators.In}>
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: filterName }],
                                    }}
                                    renderOption={(props, option, { selected }): JSX.Element => (
                                      <PeriodTypeOption props={props} option={option} selected={selected} />
                                    )}
                                    filterName={filterName}
                                    onChange={value => onChange(value)}
                                    fieldLabel={formatMessage({ id: 'schemas.request.periodTypes.label' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </Box>
                          </FilterSection>
                          <FilterSection
                            defaultCollapsed
                            label={formatMessage({ id: 'schemas.filter.additionalInformation' })}
                          >
                            <Box mb={2}>
                              <Filter operator={Operators.In} name="provider.ownerUserId">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'provider.ownerUser.name' }],
                                    }}
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.organization.ownerUserId' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </Box>

                            <Box mb={2}>
                              <Filter operator={Operators.In} name="provider.finalRiskRating">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'provider.finalRiskRating', isEnum: true }],
                                    }}
                                    renderOption={(props, option, { selected }): JSX.Element => (
                                      <li {...props}>
                                        <OptionIcon selected={selected} multiple />
                                        <FinalRiskRatingIcon value={option?.label} />
                                        {option?.label
                                          ? formatMessage({
                                              id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                            })
                                          : ''}
                                      </li>
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option?.value?.toString() || index}
                                              icon={<FinalRiskRatingIcon value={option.label} />}
                                              label={formatMessage({
                                                id: `schemas.provider.finalRiskRatingValues.${option.label}`,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.provider.finalRiskRating' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </Box>
                            <Box mb={2}>
                              <Filter operator={Operators.In} name="provider.priority">
                                {({ value, onChange, filterName }) => (
                                  <FilterFacetSelect
                                    implicitFilters={implicitFilters}
                                    size="small"
                                    multiple
                                    facetsParam={{
                                      key: [FetchKey.RequestFacets, filterName],
                                      endpoint: endpoints.inquiryWithFacets,
                                      facetsParam: [{ name: 'provider.priority', isEnum: true }],
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                      <OptionWithLabel
                                        key={option.label}
                                        props={props}
                                        label={formatMessage({
                                          id: option?.label
                                            ? `schemas.provider.priorityValues.${option.label}`
                                            : 'general.notAvailable',
                                        })}
                                        multiple
                                        selected={selected}
                                      />
                                    )}
                                    renderTags={(value, getTagProps) =>
                                      value?.map(
                                        (option, index) =>
                                          option && (
                                            <Chip
                                              {...getTagProps({ index })}
                                              key={option?.value?.toString() || index}
                                              label={formatMessage({
                                                id: `schemas.provider.priorityValues.${option.label}`,
                                              })}
                                              size="small"
                                            />
                                          ),
                                      )
                                    }
                                    filterName={filterName}
                                    onChange={onChange}
                                    fieldLabel={formatMessage({ id: 'schemas.provider.priority' })}
                                    value={[value].flat()}
                                  />
                                )}
                              </Filter>
                            </Box>
                            {solution !== Solutions.Finance && (
                              <>
                                <Box mb={2}>
                                  <Filter operator={Operators.In} name="provider.activityStatus">
                                    {({ value, onChange, filterName }) => (
                                      <FilterFacetSelect
                                        implicitFilters={implicitFilters}
                                        size="small"
                                        multiple
                                        facetsParam={{
                                          key: [FetchKey.RequestFacets, filterName],
                                          endpoint: endpoints.inquiryWithFacets,
                                          facetsParam: [{ name: 'provider.activityStatus', isEnum: true }],
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                          <OptionWithLabel
                                            key={option.label}
                                            props={props}
                                            label={formatMessage({
                                              id: option?.label
                                                ? `schemas.provider.activityStatusValues.${option.label}`
                                                : 'general.notAvailable',
                                            })}
                                            multiple
                                            selected={selected}
                                          />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                          value?.map(
                                            (option, index) =>
                                              option && (
                                                <Chip
                                                  {...getTagProps({ index })}
                                                  key={option?.value?.toString() || index}
                                                  label={formatMessage({
                                                    id: `schemas.provider.activityStatusValues.${option.label}`,
                                                  })}
                                                  size="small"
                                                />
                                              ),
                                          )
                                        }
                                        filterName={filterName}
                                        onChange={onChange}
                                        fieldLabel={formatMessage({ id: 'schemas.provider.activityStatus' })}
                                        value={[value].flat()}
                                      />
                                    )}
                                  </Filter>
                                </Box>
                                <Box mb={2}>
                                  <Filter operator={Operators.In} name="provider.providerApprovalStatus">
                                    {({ value, onChange, filterName }) => (
                                      <FilterFacetSelect
                                        implicitFilters={implicitFilters}
                                        size="small"
                                        multiple
                                        facetsParam={{
                                          key: [FetchKey.RequestFacets, filterName],
                                          endpoint: endpoints.inquiryWithFacets,
                                          facetsParam: [{ name: 'provider.providerApprovalStatus', isEnum: true }],
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                          <OptionWithLabel
                                            key={option.label}
                                            props={props}
                                            label={formatMessage({
                                              id: option?.label
                                                ? `schemas.provider.providerApprovalStatusValues.${option.label}`
                                                : 'general.notAvailable',
                                            })}
                                            multiple
                                            selected={selected}
                                          />
                                        )}
                                        renderTags={(value, getTagProps) =>
                                          value?.map(
                                            (option, index) =>
                                              option && (
                                                <Chip
                                                  {...getTagProps({ index })}
                                                  key={option?.value?.toString() || index}
                                                  label={formatMessage({
                                                    id: `schemas.provider.providerApprovalStatusValues.${option.label}`,
                                                  })}
                                                  size="small"
                                                />
                                              ),
                                          )
                                        }
                                        filterName={filterName}
                                        onChange={onChange}
                                        fieldLabel={formatMessage({
                                          id: 'schemas.provider.providerApprovalStatus',
                                        })}
                                        value={[value].flat()}
                                      />
                                    )}
                                  </Filter>
                                </Box>
                              </>
                            )}
                            {renderWithPermission({
                              [Permissions.SOURCING_USER]: (
                                <>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="provider.supplierUsage">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.RequestFacets, filterName],
                                            endpoint: endpoints.inquiryWithFacets,
                                            facetsParam: [{ name: 'provider.supplierUsage', isEnum: true }],
                                          }}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage({
                                                id: option?.label
                                                  ? `schemas.provider.supplierUsageValues.${option.label}`
                                                  : 'general.notAvailable',
                                              })}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage({
                                                      id: `schemas.provider.supplierUsageValues.${option.label}`,
                                                    })}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.supplierUsage' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                  <Box mb={2}>
                                    <Filter operator={Operators.In} name="provider.tier">
                                      {({ value, onChange, filterName }) => (
                                        <FilterFacetSelect
                                          implicitFilters={implicitFilters}
                                          size="small"
                                          multiple
                                          facetsParam={{
                                            key: [FetchKey.RequestFacets, filterName],
                                            endpoint: endpoints.inquiryWithFacets,
                                            facetsParam: [{ name: 'provider.tier', isEnum: true }],
                                          }}
                                          filterOptions={(options, state) => [...tierFilter(options, state)]}
                                          renderOption={(props, option, { selected }) => (
                                            <OptionWithLabel
                                              key={option.label}
                                              props={props}
                                              label={formatMessage(
                                                {
                                                  id: `schemas.provider.tierValues`,
                                                },
                                                { tier: option?.label ?? '' },
                                              )}
                                              multiple
                                              selected={selected}
                                            />
                                          )}
                                          renderTags={(value, getTagProps) =>
                                            value?.map(
                                              (option, index) =>
                                                option && (
                                                  <Chip
                                                    {...getTagProps({ index })}
                                                    key={option?.value?.toString() || index}
                                                    label={formatMessage(
                                                      {
                                                        id: `schemas.provider.tierValues`,
                                                      },
                                                      { tier: option.label },
                                                    )}
                                                    size="small"
                                                  />
                                                ),
                                            )
                                          }
                                          filterName={filterName}
                                          onChange={onChange}
                                          fieldLabel={formatMessage({ id: 'schemas.provider.tier' })}
                                          value={[value].flat()}
                                        />
                                      )}
                                    </Filter>
                                  </Box>
                                </>
                              ),
                            })}
                          </FilterSection>
                          <CustomCategoriesFilters baseFilterName="provider" />
                        </Box>
                      </DrawerViewFilters>,
                    )
                  }
                />
              </Box>

              {isFiltered && (
                <Button variant="text" onClick={clearQueryFilter} sx={{ ml: 1 }}>
                  {formatMessage({ id: 'filters.clearAll' })}
                </Button>
              )}
            </Grid>

            <Filter name="template.tags.tag" operator={Operators.In}>
              {({ value, onChange }) => (
                <>
                  {value && (
                    <Grid item xs={12}>
                      <Chip label={`${value} requests`} onDelete={() => onChange(null)} />
                    </Grid>
                  )}
                </>
              )}
            </Filter>
          </>
        )}
      </Filters>
    </Grid>
  )
}

export default RequestsFilters
