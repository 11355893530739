import {
  AssessmentRuleType,
  Question,
  QuestionAnswerClassificationRule,
  QuestionAnswerRank,
} from '@app/src/types/resourceExplorer'
import { EditQuestionFormData, SubmitData } from '../EditQuestion'
import { NumberFields } from './NumberClassification'

export type QuestionAnswerClassificationRuleFormData = {
  [key in QuestionAnswerRank]: Array<QuestionAnswerClassificationRule> | undefined
}

export type QuestionNumberClassificationRuleFormData = {
  [key in NumberFields]: QuestionAnswerClassificationRule | undefined
}

export type QuestionOptionClassificationRuleFormData = {
  [key in QuestionAnswerRank]: Array<QuestionAnswerClassificationRule> | undefined
}

export const mapToAnswerClassificationDataFormData = (
  questionAnswerClassificationRules?: Question['questionAnswerClassificationRules'],
): EditQuestionFormData['questionAnswerClassificationRules'] => {
  return questionAnswerClassificationRules
    ?.filter(item => item?.ruleType === AssessmentRuleType.AnswerStatus)
    ?.reduce<QuestionAnswerClassificationRuleFormData>(
      (prev, curr) => {
        if (!curr?.rank) return prev

        if (!prev[curr.rank]) {
          prev[curr.rank] = [curr]
        } else {
          prev[curr.rank]?.push(curr)
        }
        return prev
      },
      {
        Negative: undefined,
        NegativeAdverse: undefined,
        Neutral: undefined,
        Positive: undefined,
        NotSet: undefined,
      },
    )
}

export const mapToAnswerClassificationSubmitData = (
  isWorldfavorOrganization: boolean,
  questionAnswerClassificationRules?: EditQuestionFormData['questionAnswerClassificationRules'],
): SubmitData['questionAnswerClassificationRules'] => {
  if (!isWorldfavorOrganization) return

  return Object.values(questionAnswerClassificationRules ?? {})
    .filter(Boolean)
    .map(value => value)
    .flat()
}

export const mapToNumberClassificationDataFormData = (
  questionAnswerClassificationRules?: Question['questionAnswerClassificationRules'],
): EditQuestionFormData['questionNumberClassificationRules'] => {
  return questionAnswerClassificationRules
    ?.filter(
      item =>
        item?.ruleType === AssessmentRuleType.NumberEqualTo ||
        item?.ruleType === AssessmentRuleType.NumberGreaterThan ||
        item?.ruleType === AssessmentRuleType.NumberLowerThan,
    )
    ?.reduce<QuestionNumberClassificationRuleFormData>(
      (prev, curr) => {
        if (!curr?.ruleType) return prev

        if (
          curr.ruleType === AssessmentRuleType.NumberEqualTo ||
          curr.ruleType === AssessmentRuleType.NumberGreaterThan ||
          curr.ruleType === AssessmentRuleType.NumberLowerThan
        ) {
          prev[curr.ruleType] = curr
        }
        return prev
      },
      {
        [AssessmentRuleType.NumberEqualTo]: undefined,
        [AssessmentRuleType.NumberGreaterThan]: undefined,
        [AssessmentRuleType.NumberLowerThan]: undefined,
      },
    )
}

export const mapToNumberClassificationSubmitData = (
  isWorldfavorOrganization: boolean,
  questionNumberClassificationRules: EditQuestionFormData['questionNumberClassificationRules'],
): SubmitData['questionAnswerClassificationRules'] => {
  if (!isWorldfavorOrganization) return

  return Object.entries(questionNumberClassificationRules ?? {})
    .filter(([_, value]) => Boolean(value?.value))
    .map(([key, value]) => ({
      rank: value?.rank ?? QuestionAnswerRank.NotSet,
      ruleType: AssessmentRuleType[key as keyof typeof AssessmentRuleType],
      value: value?.value,
    }))
}

export const mapToOptionClassificationDataFormData = (
  questionAnswerClassificationRules?: Question['questionAnswerClassificationRules'],
): EditQuestionFormData['questionOptionClassificationRules'] => {
  return questionAnswerClassificationRules
    ?.filter(item => item?.ruleType === AssessmentRuleType.SelectedValue)
    ?.reduce<QuestionOptionClassificationRuleFormData>(
      (prev, curr) => {
        if (!curr?.rank) return prev

        if (!prev[curr.rank]) {
          prev[curr.rank] = [curr]
        } else {
          prev[curr.rank]?.push(curr)
        }

        return prev
      },
      {
        Negative: undefined,
        NegativeAdverse: undefined,
        Neutral: undefined,
        Positive: undefined,
        NotSet: undefined,
      },
    )
}

export const mapToOptionClassificationSubmitData = (
  isWorldfavorOrganization: boolean,
  questionAnswerClassificationRules?: EditQuestionFormData['questionOptionClassificationRules'],
): SubmitData['questionAnswerClassificationRules'] => {
  if (!isWorldfavorOrganization) return

  return Object.values(questionAnswerClassificationRules ?? {})
    .filter(Boolean)
    .map(value => value)
    .flat()
}

export const mapToClassificationSubmitData = (isWorldfavorOrganization: boolean, formData: EditQuestionFormData) => {
  const answerClassification = mapToAnswerClassificationSubmitData(
    isWorldfavorOrganization,
    formData.questionAnswerClassificationRules,
  )
  const numberClassification = mapToNumberClassificationSubmitData(
    isWorldfavorOrganization,
    formData.questionNumberClassificationRules,
  )
  const optionClassification = mapToOptionClassificationSubmitData(
    isWorldfavorOrganization,
    formData.questionOptionClassificationRules,
  )

  return [answerClassification, numberClassification, optionClassification]
    .flat()
    .filter(item => item?.rank !== QuestionAnswerRank.NotSet)
}
