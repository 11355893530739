import {
  HistoryStatus,
  ReportAccessModel,
  Request,
  RequestHistory,
  ResponseDraftStatus,
  ResponseItem,
  ResponseWithPreviousCorrectionNeededItem,
} from '@app/src/types/resourceExplorer'
import { createContext, useContext } from 'react'
import { ReuseAllQuestionsStates } from './ReportContextProvider'

export interface LanguageInfo {
  code: string
  name: string
}

export type ReportContextProps = {
  responseId?: number
  requestId?: number
  request?: Request
  response?: ResponseWithPreviousCorrectionNeededItem
  requestsPerPeriod?: Request[]
  currentAccess?: ReportAccessModel
  requestHistory?: RequestHistory[]
  latestHistory?: RequestHistory
  reportStatus?: ResponseDraftStatus | HistoryStatus
  isPreview?: boolean
  previouslyRespondedQuestions?: ResponseItem[]
  locale: LanguageInfo | null
  showAccessNudge: boolean
  isRequestLoading: boolean
  isResponseLoading: boolean
  isResponseFetching: boolean
  isCurrentAccessLoading: boolean
  isRequestHistoryLoading: boolean
  isRequestsPerPeriodLoading: boolean
  isLoadingAccessNudge: boolean
  isPreviouslyRespondedQuestionsLoading: boolean
  reuseAllQuestionsState: ReuseAllQuestionsStates
  updateResponseIdParam: (responseId?: string | number) => void
  updateRequestIdParam: (requestId?: string | number) => void
  setLocale: (locale: LanguageInfo | null) => void
  setShowAccessNudge: (show: boolean) => void
  setLoadingAccessNudge: (loading: boolean) => void
  setReuseAllQuestionsState: (state: ReuseAllQuestionsStates) => void
  showPreviousAnswerSuggestions: boolean
  setShowPreviousAnswerSuggestions: (state: boolean) => void
}

const ReportContext = createContext<ReportContextProps>({
  isPreview: false,
  locale: null,
  showAccessNudge: false,
  isRequestLoading: true,
  isResponseFetching: true,
  isResponseLoading: true,
  isCurrentAccessLoading: true,
  isRequestHistoryLoading: true,
  isRequestsPerPeriodLoading: true,
  isLoadingAccessNudge: true,
  isPreviouslyRespondedQuestionsLoading: true,
  reuseAllQuestionsState: ReuseAllQuestionsStates.Review,
  updateResponseIdParam: () => undefined,
  updateRequestIdParam: () => undefined,
  setLocale: () => undefined,
  setShowAccessNudge: () => undefined,
  setLoadingAccessNudge: () => undefined,
  setReuseAllQuestionsState: () => undefined,
  showPreviousAnswerSuggestions: true,
  setShowPreviousAnswerSuggestions: () => undefined,
})

export const useReport = (): ReportContextProps => {
  return useContext(ReportContext)
}

export default ReportContext
