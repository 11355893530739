import endpoints from '@app/src/api/endpoints'
import { FetchKey, useFetchCollectionWithPost } from '@app/src/api/fetchHooks'
import { useDrawer } from '@app/src/components/Drawer/DrawerContext'
import DrawerViewExport from '@app/src/components/Drawer/Views/DrawerViewExport'
import EmptyState from '@app/src/components/EmptyState'
import Table from '@app/src/components/Table'
import { InquiryExportColumnsAccessor } from '@app/src/export-columns/inquiry'
import usePagination from '@app/src/hooks/pagination'
import { useGetApiQueryFilters } from '@app/src/hooks/queryFilters'
import useSort from '@app/src/hooks/sorting'
import InquiryHeader from '@app/src/pages/ResourceCollection/Collections/Request/AccessorInquiryHeader'
import RequestsFilters from '@app/src/pages/ResourceCollection/Filters/RequestsFilters'
import { FilterGroup } from '@app/src/pages/ResourceCollection/Filters/useFilters'
import ResourceCollectionScene, {
  ResourceCollectionSceneProps,
} from '@app/src/pages/ResourceCollection/ResourceCollectionScene'
import { Inquiry } from '@app/src/types/resourceExplorer'
import { br } from '@app/src/utils/translationMarkup'
import { ResourceTypes } from '@app/src/wf-constants'
import { ArrowForwardOutlined, ListAltOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import { AccessorInquiryRow } from '../Request/AccessorInquiryRow'
import { ViewTypeName } from './ManageRequestsScene'

interface AccessorRequestsSceneProps {
  relatedContentFilter?: FilterGroup[]
  embedded?: boolean
  tabs?: ResourceCollectionSceneProps['tabs']
  actionButtons?: ResourceCollectionSceneProps['actionButtons']
  setCreateDialogOpen?: () => void
}

const allowedFilters = [
  'template.id',
  'status',
  'periodType',
  'provider.websiteAddress',
  'provider.country.id',
  'provider.vatNumber',
  'provider.organization.id',
  'request.responseExternalStatus',
  'request.responseInternalStatus',
  'isActivated',
  'deadline',
  'createdAt',
  'activatedAt',
  'periodName',
  'provider.providerApprovalStatus',
  'provider.priority',
  'provider.finalRiskRating',
  'provider.activityStatus',
  'provider.ownerUserId',
  'provider.supplierUsage',
  'provider.tier',
  'provider.categoryOptions.id',
  'template.tags.tag',
  'provider.spends.amount',
  'provider.spends.periodName',
]

const AccessorRequestsScene: React.FC<AccessorRequestsSceneProps> = ({
  embedded,
  tabs,
  actionButtons = [],
  relatedContentFilter = [],
  setCreateDialogOpen,
}) => {
  const { formatMessage } = useIntl()
  const { sorting, toggleSorting } = useSort()
  const [page, pageSize, setPage, setPageSize, resetPage] = usePagination()
  const apiFilters = useGetApiQueryFilters(allowedFilters)
  const { openDrawer } = useDrawer()

  const basePayload = {
    filter: relatedContentFilter,
    sort: sorting,
    include: [
      'creatorOrganization',
      'request.responses',
      'request.responses.verifications',
      'template',
      'template.image',
      'provider.organization',
    ],
  }

  const payload = {
    ...basePayload,
    filter: [...basePayload.filter, ...apiFilters],
    pagination: {
      pageNumber: page,
      itemsPerPage: pageSize,
    },
  }

  const { items, count, isLoading, isFetching, isError } = useFetchCollectionWithPost<Inquiry>({
    key: FetchKey.Request,
    endpoint: endpoints.inquiriesCollection,
    payload,
  })

  return (
    <>
      <ResourceCollectionScene
        title={formatMessage({ id: 'navbar.manageRequests' })}
        tabs={tabs}
        actionButtons={[
          {
            label: formatMessage({ id: 'resourceCollections.general.export' }),
            variant: 'outlined',
            onClick: () =>
              openDrawer(
                <DrawerViewExport
                  resourceType={ResourceTypes.Inquiry}
                  count={count}
                  userFilter={apiFilters}
                  exportColumns={InquiryExportColumnsAccessor}
                  rawExportPayload={{
                    ...basePayload,
                    include: [...basePayload.include, 'target'],
                  }}
                />,
              ),
            disabled: tabs?.activeTabParam === ViewTypeName.Overview || isLoading || count === 0,
          },
          ...actionButtons,
        ]}
        filter={
          <RequestsFilters
            implicitFilters={relatedContentFilter}
            allowedFilters={allowedFilters}
            resetPage={resetPage}
          />
        }
        embedded={embedded}
      >
        <Table<Inquiry>
          RowComponent={({ row }) => <AccessorInquiryRow row={row} />}
          HeaderComponent={() => <InquiryHeader toggleSorting={toggleSorting} activeSorting={sorting} />}
          data={items}
          isLoading={isLoading || isFetching}
          count={count}
          isError={isError}
          page={page}
          pageSize={pageSize}
          setPage={setPage}
          setPageSize={setPageSize}
          emptyState={
            <EmptyState
              iconComponent={ListAltOutlined}
              title={formatMessage({ id: 'table.manageRequests.requestsEmptyState.title' })}
              description={formatMessage({ id: 'table.manageRequests.requestsEmptyState.description' }, { br })}
              sx={{ my: 2 }}
            >
              {setCreateDialogOpen && (
                <Button
                  variant="outlined"
                  endIcon={<ArrowForwardOutlined fontSize="small" />}
                  onClick={setCreateDialogOpen}
                >
                  {formatMessage({ id: 'table.manageRequests.requestsEmptyState.action' })}
                </Button>
              )}
            </EmptyState>
          }
          stickyColumnIndex={2}
        />
      </ResourceCollectionScene>
    </>
  )
}

export default AccessorRequestsScene
