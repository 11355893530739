import endpoints from '@app/src/api/endpoints'
import { useCreateResource, useUpdateResourceWithoutBody } from '@app/src/api/updateHooks'
import ControlledDateField from '@app/src/components/Form/ControlledDateField'
import LoadingButton from '@app/src/components/LoadingButton'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import useErrorNotification from '@app/src/hooks/errorNotification'
import SkipQuestionButton from '@app/src/pages/Questionnaire/Provider/Report/SkipQuestionButton'
import SuggestAnswerButton from '@app/src/pages/Questionnaire/Provider/Report/SuggestAnswerButton'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import { RequestItem, UploadReference } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import AttachmentIcon from '@mui/icons-material/Attachment'
import CropDinIcon from '@mui/icons-material/CropDin'
import { Box, FormHelperText, Stack, Typography, useTheme } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface Props {
  name: string
  id: number
  sourceObjectId: number
  sourceObjectType: string
  validationError: string
  requestItem: RequestItem
  required?: boolean
  disabled?: boolean
  isPreview: boolean
}

const DirectFileUploadField = ({
  name,
  id,
  validationError,
  required,
  disabled,
  sourceObjectId,
  sourceObjectType,
  isPreview,
  requestItem,
}: Props): JSX.Element => {
  const { register, setValue, control, clearErrors, watch, trigger } = useFormContext()
  const { formatMessage } = useIntl()
  const { showErrorNotification } = useErrorNotification()
  const { spacing, palette } = useTheme()
  const { setResponseItemView, previousAnswer, hideInputField, setQuestionSkippedFields } = useResponseItemContext()
  const { reuseAllQuestionsState } = useReport()

  const watchOriginalFileName = disabled ? '' : watch(`${name}.originalFileName`)

  const { mutate, isLoading } = useCreateResource<UploadReference, FormData>({
    options: {
      onError: error =>
        showErrorNotification({
          requestError: error,
          specificFallbackErrorMessage: formatMessage({ id: 'reporting.uploadFileError' }),
        }),
    },
  })

  const { mutateAsync: mutateCloneFile, isLoading: isMutateCloneFileLoading } = useUpdateResourceWithoutBody({
    method: 'put',
  })

  const onDrop = useCallback(
    async (acceptedFiles: Array<Blob>) => {
      if (isPreview) return

      const formData = new FormData()
      formData.append('file', acceptedFiles[0])
      mutate(
        { url: endpoints.fileUpload(sourceObjectId, sourceObjectType), body: formData },
        {
          onSuccess: ({ originalFileName }) => {
            setResponseItemView(prevState =>
              prevState === ResponseItemView.SuggestedAnswerAccepted ? ResponseItemView.ShowSuggestedAnswer : prevState,
            )
            setValue(`${name}.originalFileName`, originalFileName, { shouldValidate: true, shouldDirty: true })
          },
        },
      )
    },
    [name, setValue],
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
  const displayValue = isDragActive
    ? formatMessage({ id: 'reporting.dropFile' })
    : watchOriginalFileName || formatMessage({ id: 'reporting.uploadFile' }) + (required ? ' *' : null)

  const onApply = async () => {
    if (!previousAnswer) return
    if (previousAnswer.cannotAnswer) {
      setQuestionSkippedFields()
      setResponseItemView(ResponseItemView.SuggestedAnswerAccepted)
      await trigger()
      return
    }
    await mutateCloneFile(
      { url: endpoints.reuseFile(previousAnswer.id, sourceObjectId) },
      {
        onSuccess: async () => {
          setValue(`${name}.originalFileName`, previousAnswer?.uploadReferences?.[0]?.originalFileName, {
            shouldValidate: true,
            shouldDirty: true,
          })
          setValue(`${id}.expiresAt`, previousAnswer?.expiresAt)
          setResponseItemView(ResponseItemView.SuggestedAnswerAccepted)
          await trigger()
        },
      },
    )
  }

  useEffect(() => {
    if (reuseAllQuestionsState === ReuseAllQuestionsStates.ApplyAll) {
      onApply()
    } else {
      setResponseItemView(ResponseItemView.ShowSuggestedAnswer)
    }
  }, [reuseAllQuestionsState])

  return (
    <>
      <Stack px={4} pb={2} spacing={3} display={hideInputField ? 'none' : 'flex'}>
        <Stack>
          <Stack width="100%" direction="row" alignItems="center" spacing={2}>
            <Stack flex={1} {...getRootProps()} data-testid="upload-file">
              <input type="hidden" name={`${name}.originalFileName`} ref={register} disabled={disabled} />
              <input
                type="hidden"
                name={`${name}.sourceObjectId`}
                ref={register}
                disabled={disabled}
                defaultValue={sourceObjectId}
              />
              <input
                type="hidden"
                name={`${name}.sourceObjectType`}
                ref={register}
                disabled={disabled}
                defaultValue={sourceObjectType}
              />

              <input {...getInputProps()} disabled={disabled} />
              <LoadingButton
                name={`${name}.fileUpload`}
                variant="outlined"
                color="primary"
                disabled={disabled}
                loading={isLoading}
                onClick={(): void => clearErrors(`${id}`)}
                endIcon={<AttachmentIcon fontSize="small" />}
                sx={{
                  borderColor: validationError ? palette.error.dark : undefined,
                  borderRadius: 1,
                  borderWidth: 0.5,
                }}
              >
                <Typography variant="body1" noWrap>
                  {displayValue}
                </Typography>
              </LoadingButton>
            </Stack>

            <CropDinIcon
              fontSize="small"
              sx={{
                transform: 'rotate(45deg)',
              }}
            />
            <ControlledDateField
              fieldLabel={formatMessage({ id: 'reporting.expiresAt' })}
              control={control}
              name={`${id}.expiresAt`}
              disabled={disabled}
              slotProps={{ textField: { size: 'small' } }}
            />
          </Stack>
          {validationError && (
            <FormHelperText
              sx={{
                color: palette.secondary.dark,
                paddingLeft: spacing(2),
              }}
              error
            >
              {validationError}
            </FormHelperText>
          )}
        </Stack>
      </Stack>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" px={4}>
        <Box flexGrow={1}>
          <SkipQuestionButton requestItem={requestItem} />
        </Box>
        <SuggestAnswerButton
          requestItem={requestItem}
          questionType={QuestionTypes.File}
          onApplyPreviousAnswer={onApply}
          onApplyAiSuggestion={() => undefined}
          isPreview={isPreview}
          isLoadingCloneFile={isMutateCloneFileLoading}
        />
      </Box>
    </>
  )
}

export default DirectFileUploadField
