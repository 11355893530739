import { Provider } from '@app/src/types/organizations'
import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import CountryCell from '../Table/Cells/CountryCell'
import ValueCell from '../Table/Cells/ValueCell'

interface OrganizationInformationProps {
  provider: Provider
}

const OrganizationInformation: React.FC<OrganizationInformationProps> = ({ provider }) => {
  const { formatMessage } = useIntl()
  return (
    <>
      <Typography variant="subtitle1" color="textSecondary" mb={2}>
        {formatMessage({ id: 'general.orgInformation' })}
      </Typography>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.organization.address' })}</Typography>
          <ValueCell value={provider?.organization?.locations?.[0]?.address} disableCell color="textSecondary" />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.country' })}</Typography>
          <CountryCell countryCode={provider?.organization?.country?.countryCode} disableCell color="textSecondary" />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.organization.websiteAddress' })}</Typography>
          <ValueCell value={provider?.organization?.websiteAddress} disableCell color="textSecondary" />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.organization.industry' })}</Typography>
          <ValueCell value={provider?.organization?.industryNaceCode?.description} disableCell color="textSecondary" />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.organization.subIndustry' })}</Typography>
          <ValueCell
            value={provider?.organization?.subIndustryNaceCode?.description}
            disableCell
            color="textSecondary"
          />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.organization.employees' })}</Typography>
          <ValueCell
            value={
              provider?.organization?.companySizeRange
                ? formatMessage({ id: `claim.companySize.${provider.organization.companySizeRange}` })
                : '-'
            }
            disableCell
            color="textSecondary"
          />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.organization.revenue' })}</Typography>
          <ValueCell
            value={
              provider?.organization?.revenueRange
                ? formatMessage({ id: `claim.companyRevenue.${provider.organization.revenueRange}` })
                : '-'
            }
            disableCell
            color="textSecondary"
          />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.vatNumber' })}</Typography>
          <ValueCell value={provider.organization?.vatNumber} disableCell color="textSecondary" />
        </Stack>
        <Stack>
          <Typography variant="body2">{formatMessage({ id: 'schemas.supplier.registrationNumber' })}</Typography>
          <ValueCell value={provider.organization?.registrationNumber} disableCell color="textSecondary" />
        </Stack>
      </Stack>
    </>
  )
}

export default OrganizationInformation
