import TextField from '@app/src/components/Ui/TextField'
import { useResponseItemContext } from '@app/src/context/ResponseItemContext'
import { ResponseItemView } from '@app/src/context/ResponseItemContextProvider'
import SuggestAnswerButton from '@app/src/pages/Questionnaire/Provider/Report/SuggestAnswerButton'
import { useReport } from '@app/src/pages/Questionnaire/ReportContext'
import { ReuseAllQuestionsStates } from '@app/src/pages/Questionnaire/ReportContextProvider'
import { RequestItem } from '@app/src/types/resourceExplorer'
import { QuestionTypes } from '@app/src/wf-constants'
import { Box, InputAdornment, Stack } from '@mui/material'
import { capitalize } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import SkipQuestionButton from './SkipQuestionButton'

type TextFieldWithSuggestionsProps = {
  requestItem: RequestItem
  disabled?: boolean
  hasError?: boolean
  error?: string
  isPreview: boolean
}

const getFieldValueFromSuggestion = (value: string, questionType: string) => {
  if (questionType?.toLowerCase() !== QuestionTypes.Number.toLowerCase()) return value

  const match = value.match(/\d+(?:[.,]\d+)?/)
  if (!match) return undefined
  return parseFloat(match[0]?.replace(',', '.'))
}

const TextFieldWithSuggestions: React.FC<TextFieldWithSuggestionsProps> = ({
  requestItem,
  disabled,
  hasError,
  error,
  isPreview,
}) => {
  const { register, clearErrors, setValue, trigger } = useFormContext()
  const [hasAppliedRecommendation, setHasAppliedRecommendation] = useState(false)
  const { reuseAllQuestionsState } = useReport()
  const { hideInputField, setResponseItemView, previousAnswer, setQuestionSkippedFields } = useResponseItemContext()
  const [appliedValue, setAppliedValue] = useState<string>()

  const name = `${requestItem.id}.answer`
  const valueWatch = useWatch({ name })

  const questionType = requestItem.questionType?.name.toLowerCase()

  useEffect(() => {
    if (!appliedValue || appliedValue === valueWatch) return
    setAppliedValue(undefined)
    setResponseItemView(prevState =>
      prevState === ResponseItemView.SuggestedAnswerAccepted ? ResponseItemView.ShowSuggestedAnswer : prevState,
    )
  }, [valueWatch])

  const applyValueFromString = (value: string) => {
    const valueToSet = getFieldValueFromSuggestion(value, questionType)
    setValue(name, valueToSet)
    setAppliedValue(valueToSet?.toString())
  }

  const afterRecommendationsApplied = async () => {
    setHasAppliedRecommendation(true)
    setResponseItemView(ResponseItemView.SuggestedAnswerAccepted)
    await trigger()
  }

  const onApplyAiSuggestion = async (value: string) => {
    applyValueFromString(value)
    await afterRecommendationsApplied()
  }

  const onApplyPreviousAnswer = async (value: string) => {
    if (!previousAnswer) return
    if (previousAnswer.cannotAnswer) setQuestionSkippedFields()
    else {
      applyValueFromString(value)
    }
    await afterRecommendationsApplied()
  }

  useEffect(() => {
    switch (reuseAllQuestionsState) {
      case ReuseAllQuestionsStates.ApplyAll:
        onApplyPreviousAnswer(previousAnswer?.answer.toString() ?? '')
        break
      default:
        setResponseItemView(ResponseItemView.ShowSuggestedAnswer)
    }
  }, [reuseAllQuestionsState])

  return (
    <>
      <Stack display={hideInputField ? 'none' : undefined} spacing={3} px={4} pb={2}>
        <TextField
          required
          onWheelCapture={(e): void => {
            ;(e.target as HTMLInputElement).blur()
          }}
          inputRef={register}
          name={name}
          label={capitalize(questionType)}
          disabled={disabled}
          error={hasError}
          helperText={error}
          type={questionType}
          multiline={questionType === 'text'}
          size="small"
          onChange={(): void => {
            if (hasError) clearErrors(`${requestItem.id}`)
          }}
          inputProps={{
            step: questionType === 'number' ? 'any' : undefined,
          }}
          InputProps={{
            endAdornment: requestItem.unit && <InputAdornment position="end">{requestItem.unit.symbol}</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: hasAppliedRecommendation ? true : undefined,
          }}
          sx={{
            width: '100%',
            '& input:disabled': {
              background: 'background.default',
            },
          }}
        />
      </Stack>
      <Box display="flex" justifyContent="space-between" alignItems="flex-start" px={4}>
        <Box flexGrow={1}>
          <SkipQuestionButton requestItem={requestItem} />
        </Box>
        <SuggestAnswerButton
          requestItem={requestItem}
          questionType={questionType}
          onApplyPreviousAnswer={async () => await onApplyPreviousAnswer(previousAnswer?.answer.toString() ?? '')}
          onApplyAiSuggestion={onApplyAiSuggestion}
          isPreview={isPreview}
        />
      </Box>
    </>
  )
}

export default TextFieldWithSuggestions
