import { AssessmentTemplate, AssessmentType } from '@app/src/types/resourceExplorer'
import { Close } from '@mui/icons-material'
import { Box, Dialog, IconButton, Stack, Typography } from '@mui/material'
import React from 'react'
import { useIntl } from 'react-intl'
import AssessmentModalTable from '../DataHub/DataInsights/AssessmentsModalTable'

type AssessmentDialogProps = {
  assessmentTemplate: AssessmentTemplate
  period?: number
  activeLevel?: number
  isOpen: boolean
  closeModal: () => void
}

const AssessmentDialog: React.FC<AssessmentDialogProps> = ({
  assessmentTemplate,
  period,
  activeLevel,
  isOpen,
  closeModal,
}) => {
  const { formatMessage } = useIntl()
  const isBaselineAssessment = assessmentTemplate.assessmentType === AssessmentType.BaselineAssessment
  const levelLabel = isBaselineAssessment
    ? formatMessage({ id: 'baseAssessment.totalLevel' }, { value: activeLevel })
    : assessmentTemplate.levels === 3
      ? formatMessage({ id: `assessments.levels.3.level${activeLevel}` })
      : formatMessage({ id: `assessments.levels.5.level${activeLevel}` })

  return (
    <Dialog open={isOpen} onClose={closeModal} fullScreen sx={{ m: 10 }} PaperProps={{ sx: { borderRadius: 2 } }}>
      <Box height="100%" margin={0} display="flex" flexDirection="column">
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" py={2} px={4}>
          <Stack>
            <Typography variant="h6">{formatMessage({ id: 'assessments.title' })}</Typography>
          </Stack>

          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>
        </Box>

        <Box px={4} py={1} bgcolor="grey.300">
          <Typography color="textSecondary">
            {formatMessage(
              { id: 'assessments.breadcrumbs' },
              {
                hasActiveLevel: Boolean(activeLevel),
                name: assessmentTemplate.name,
                period,
                activeLevel: levelLabel,
              },
            )}
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" flexGrow={1} height={10} pl={4} bgcolor="grey.100">
          <AssessmentModalTable assessmentTemplate={assessmentTemplate} activeLevel={activeLevel} />
        </Box>
      </Box>
    </Dialog>
  )
}

export default AssessmentDialog
